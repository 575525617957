import axios from '../../node_modules/axios/index'

// Function to create axios instance
const createAxiosInstance = () => {
    const instance = axios.create({
        baseURL: process.env.REACT_APP_AP_API_URL,
        // baseURL: "https://testportal.sleepnavigator.com/"
        // baseURL: "https://testdme.sleepnavigator.com/"
    });

    return instance;
};

export default createAxiosInstance;
