import {  Box, Button, Checkbox, FormControl, FormControlLabel, Grid, TextField, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { profilePost, pulldownsPostData } from '../../axios/api';
import { useAxios } from '../../hooks/auth';
import { useFormik } from 'formik';
import { emailAndMobileSchema } from '../../constants/yupSchema';
import phoneNumberValidator from '../../utils/phoneNumberValidator';
import {  notificationToastError, notificationToastSuccess } from '../../utils/notification';
import PhysicianAuthorityPulldown from '../misc/PhysicianAuthorityPulldown';
import useIsPrevileges from '../../hooks/useRoleAndPrivileges';

function ProfileDetail({profileData}) {
  const { formattedPhoneNumber, numericPhoneNumber } = phoneNumberValidator(profileData?.mobileNum);
  const [notificationContactNumber, setNotificationContactNumber] = useState(numericPhoneNumber || "")
  const [formatedPhonenumber, setformatedPhonenumber] = useState(formattedPhoneNumber || "")
  const [isDisabled, setIsDisabled] = useState(false)
  const axios = useAxios();
  const [formState, setFormState] = useState({});
  const [authorizedList, setAuthorizedList] = useState([`authorisedUserId0`]);
  const [authorizedUserData, setAuthorizedUserData] = useState([])
  const { role,roleId } = useIsPrevileges();
  const isMobile = useMediaQuery("(max-width:1072px)");

  useEffect(() => {
      pulldownsPostData(axios, "physicianOrderAuthorityUser")
        .then((res) => {
          if(res?.data?.length){
            setAuthorizedUserData(res?.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  useEffect(() => {
    const authorizedGroup = profileData?.authorisedUsers?.split(":");
      if (authorizedGroup) {
        const usersGroups = [];

        for (let i = 0; i < authorizedGroup.length; i++) {
          usersGroups.push(`authorisedUserId${i}`);
          formState[`authorisedUserId${i}`] = authorizedGroup[i];
        }

        setAuthorizedList(usersGroups);
      }
  }, [profileData])

  const formik = useFormik({
    initialValues: {
      email: profileData?.email || '',
      smsPhoneNumber: profileData?.smsNotificationPhone ,
      notificationContactNumber: formatedPhonenumber || '',
      receiveSMSNotifications: Boolean(profileData?.smsNotification) || false,
      receiveEmailNotifications: Boolean(profileData?.emailNotification) || false,
      
    },
    onSubmit: async (values) => {
      const payloadData = {
        emailNotification: Number(values.receiveEmailNotifications),
        smsNotification: Number(values.receiveSMSNotifications),
        mobileNum: notificationContactNumber,
        ...formState
      }
      setIsDisabled(true)
        await profilePost(axios, "saveProfile", payloadData).then((res) => {
          if (res?.response === "1") {
            notificationToastSuccess(res?.message)
          }
          else {
            notificationToastError(res?.message)
          }
        }).catch((err) => {
           notificationToastError(err?.message)
        })
    },
    validationSchema: emailAndMobileSchema,
    validateOnBlur: true,
    validateOnSubmit: true,
    validateOnChange: true
  });
  const handlePhoneNumberChange = (event, fieldName) => {
    const { formattedPhoneNumber, numericPhoneNumber } = phoneNumberValidator(event.target.value);
    setIsDisabled(false)

    if (formattedPhoneNumber) {
      formik.setFieldValue("notificationContactNumber", formattedPhoneNumber)
      if (numericPhoneNumber.length === 10) {
        setNotificationContactNumber(numericPhoneNumber)
      }
    } else {
      formik.handleChange(event)
    }

  };

  const handleSubmit = async (e, errors, values) => {
    console.log("errors",errors);
    e.preventDefault();
    formik.submitForm();
    for (const [key, _] of Object.entries(values)) {
  
      if (errors[Object.keys(errors)[0]] === "Required") {
        notificationToastError("Please fill all the required fields")
        
        return
      }
      else {
        if (errors[Object.keys(errors)[0]]) {
          notificationToastError(errors[Object.keys(errors)[0]]) 
        }
        return;
      }

  }
}
const handleFocus = (event) => {
  if (event.target.readOnly || event.target.disabled) {
    event.preventDefault();
    event.stopPropagation();
  }
}

  const addinAuthorization = () => {
    authorizedList.push(`authorisedUserId${authorizedList.length}`);
    setAuthorizedList([...authorizedList]);
  };

  const removeinAuthorization = (paramNameIn) => {
    delete formState[paramNameIn];
    const authorizedUser = [];
    const inLabs = authorizedList.filter((value) => value !== paramNameIn);
    for (let i = 0; i < inLabs.length; i++) {
      authorizedUser.push(`authorisedUserId${i}`);
      formState[`authorisedUserId${i}`] = formState[inLabs[i]];
    }
    if (authorizedList?.length === 1) {
      notificationToastError("At least one authorized user should be selected");
      return;
    }
    delete formState[`authorisedUserId${inLabs.length}`];

    setAuthorizedList(authorizedUser);

  };

  const updateInput = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value,
    });
  };

return (
      <form
      onSubmit={
        (e) => handleSubmit(e, formik?.errors, formik?.values)

      }
    >
      <Box
        sx={{
          "& .MuiTextField-root": { m: 1 },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "20px",
        }}
        autoComplete="off"
      >
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          <TextField
            id="email"
            label="Email"
            variant="standard"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email || ""}
            name="email"
            error={!!formik.touched.email && !!formik.errors.email}
            helperText={formik.touched.email && formik.errors.email}
            inputProps={
              { readOnly: true }
            }
            onMouseDown={handleFocus}

          />
          <TextField
            id="notificationContactNumber"
            label="Phone to receive notifications"
            variant="standard"
            onBlur={formik.handleBlur}
            onChange={handlePhoneNumberChange}
            type='tel'
            value={formik.values.notificationContactNumber}
            name="notificationContactNumber"
            error={!!formik.touched.notificationContactNumber && !!formik.errors.notificationContactNumber}
            helperText={formik.touched.notificationContactNumber && formik.errors.notificationContactNumber}
          />

        </Box>
        
      {
        (roleId === 3 || roleId === 6) ?
          <>
            <Typography mx={1} mt={2} fontWeight={"bold"}>Authorised Users to Sign Order:</Typography>
            {authorizedList?.map((paramNameIn, index) => (
              <Grid item lg={12} md={12} id="location-pullDown" key={index}>
                <Box sx={{ display: "flex", alignItems: "baseline",mr:2 }} >
                  <PhysicianAuthorityPulldown
                    key={paramNameIn}
                    formState={formState}
                    updateInput={updateInput}
                    authorizedList={authorizedList}
                    setAuthorizedList={setAuthorizedList}
                    // formErrorState={formErrorState}
                    authorizedUserData={authorizedUserData}
                    attributeName={paramNameIn}
                    selectedAuthorizedAttributeNames={authorizedList.filter(
                      (paramNameFilter) => paramNameFilter !== paramNameIn
                    )}
                    style={{ maxWidth: "450px" }}
                    index={index}
                  />
                  <Box>
                    <Button
                      onClick={addinAuthorization}
                      variant="outlined"
                      sx={{ marginRight: "1rem", height: "fit-content", mb: isMobile ? 1 : 0 }}
                      data-testid="add-location-button"
                    >
                      Add User
                    </Button>

                    <Button
                      onClick={() => removeinAuthorization(paramNameIn)}
                      variant="outlined"
                      sx={{ height: "fit-content" }}
                      data-testid="remove-location-button"
                    >
                      Remove
                    </Button>
                    </Box>
                </Box>
              </Grid>
            ))}
          </>
          : ""
      }
      
        <div>
          <FormControl sx={{ m: 1 }}>
            <Box>
            <FormControlLabel
              control={<Checkbox id="receiveEmailNotifications" />}
              label="Receive Email Notifications"
              name="receiveEmailNotifications"
              checked={Boolean(formik.values.receiveEmailNotifications)}
              onChange={(event) => {
                formik.handleChange(event);
                setIsDisabled(false); // Set isDisabled to false
              }}
            />
            </Box>
  
            <Typography variant='body2' sx={{ fontSize: 12 }}>When checked, SleepNavigator® email notifications will be delivered to your inbox from SleepNavigator sleepnavigator@wellnecessities.net. These emails will be limited to SleepNavigator® activities and alerts.</Typography>
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <Box>
            <FormControlLabel
              control={<Checkbox id="receiveSMSNotifications" />}
              label="Receive SMS Notifications"
              checked={Boolean(formik.values.receiveSMSNotifications)}
              onChange={(event) => {
                formik.handleChange(event);
                setIsDisabled(false); // Set isDisabled to false
              }}
            />
            </Box>
            
            <Typography variant='body2' sx={{ fontSize: 12 }}>
              When checked, SleepNavigator® SMS notifications will be sent from  { formik?.values?.smsPhoneNumber}  to the phone number listed in your profile. These SMS messages will be limited to SleepNavigator® activities and alerts. Please note that message rates may apply as managed by your phone carrier.
              </Typography>
          </FormControl>
        </div>
        <Box sx={{ paddingTop: '1rem', paddingBottom: '1rem', paddingLeft: '1rem' }}>
          <Button variant="contained" type='submit' disabled={isDisabled}>
            SAVE CHANGES
          </Button>
        </Box>
      </Box>
    </form>
)
}

export default ProfileDetail