import { Box, Button, Container, CssBaseline, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { useAuth } from '../hooks/auth';
import { useLocation } from 'react-router-dom';
import { notificationToastError } from '../utils/notification';

const AuthLogin = () => {
    const [code, setCode] = useState("");
    const { MFALogin ,error,setError} = useAuth();
    const location = useLocation();
    const { securityCodeUserId, sms } = location.state || "";
    const [codeError, setCodeError] = useState("");

    useEffect(() => {
        if (error) {
            notificationToastError(error)
            setError(null)
        }
    }, [error])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCode(value);
        setCodeError("");
    }

    const handleSubmit = () => {

        if (!/^\d{6}$/.test(code)) {
            setCodeError("Security code must be exactly 6 numeric characters.");
            return;
        }
        MFALogin({ securityCodeUserId: securityCodeUserId, securityCode: code })
    }

  return (
      <Container
          component="main"
          maxWidth="xs"
          sx={{ backgroundColor: 'white' }}
      >
          <CssBaseline />
          <Box
              sx={{
                  marginTop: 8,
              }}
          >
              <Typography component="h2" variant="h5" width={"max-content"}>
                  Enter Security Code that {sms === 1 ? "Texted to Your Mobile Number:" : "Send to Your Email:"}
              </Typography>

              <Box
                  component="form"
                  // onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
              >
                  <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="securitycode"
                      onChange={handleChange}
                      label="Security Code"
                      name="securitycode"
                      autoComplete="securitycode"
                      autoFocus
                      error={!!codeError}
                      helperText={codeError}
                  />

                  <Button
                      fullWidth
                      variant="contained"
                      sx={{
                          mt: 3, mb: 2, bgcolor: '#715d9c', '&:hover': {
                              bgcolor: "#574b7e", // Change the hover color as needed
                          }
                      }}
                      onClick={handleSubmit}
                      disabled={!code}
                  >
                      GO
                  </Button>
              </Box>
          </Box>
          <ToastContainer />
      </Container>
  )
}

export default AuthLogin