import React, { useEffect } from "react";
import { Box, CircularProgress, Dialog, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { documentFormData } from "../../../axios/api";
import { useAxios } from "../../../hooks/auth";
import { notificationToastError, notificationToastSuccess } from "../../../utils/notification";

function CygnatureView({ setLoader, patientId, documentId, studyId, setReloadRequire, setFormSigned, setOpenCygnatureModal, openCygnatureModal, signURLState, setSignURLState = () => { }, isIframeLoaded = false }) {
  const axios = useAxios();
  
  const handleClosePdf = (e, reason) => {
    if (reason === 'backdropClick') {
      e.preventDefault()
    } else {
      setLoader(true)
      setOpenCygnatureModal(false);
      setSignURLState({...signURLState, signUrl: ""})
      documentFormData(axios, "formSigned", {
        patientId: patientId,
        studyId: studyId,
        documentId: documentId,
        signedDocId: signURLState?.signedDocId,
      }).then((res) => {
        console.log("res data==>", res);
        if(res?.response === "1"){
          notificationToastSuccess(res?.message)
          setLoader(false)
          setReloadRequire(true);
          setFormSigned(true);
          window.location.reload();
        }else{
          setLoader(false)
          notificationToastError(res?.message)
        }
      }).catch((err) => {
        console.log("err==>", err);
      })
    }
  };

  return (
    <Dialog onClose={handleClosePdf} open={openCygnatureModal} maxWidth="xl">
      <Box
        data-testid="modal-pdf-dialog"
        sx={{ height: "800px", width: "1200px" }}
      >
        {!signURLState?.isloaded ? 
        <Box>
          <Box
          sx={{
            display: "flex",
            flexDirection: !isIframeLoaded ? "row" : "column",
            justifyContent: !isIframeLoaded ? "space-between" : "",
            alignItems: isIframeLoaded ? "flex-end" : "",
            padding: "5px"
          }}
        >
          {!isIframeLoaded ? <Typography variant="h6" style={{width: "95%", textAlign: "center"}}>Please wait. Document is loading .....</Typography> : ""}
          <CloseIcon data-testid="close-button" sx={{ cursor: "pointer",float:"right" }} onClick={handleClosePdf} />
        </Box>
          <div id="iframeContainer" style={{ height: "750px", width: "1195px" }}></div>
          {/* <iframe height={800} width={1200} src={signURLState.signUrl} title="signURLState" /> */}
        </Box> : <Box sx={{ display: "flex", flexDirection: "column", gap: 2, alignItems: "center", height: "800px", width: "1200px", justifyContent: "center" }}>
            <CircularProgress />
            <Typography variant="h6">Please wait. Document is loading .....</Typography>
          </Box>}
      </Box>
    </Dialog>
  );
}
export default React.memo(CygnatureView);
