import { useEffect, useState } from "react";
import * as React from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "../../../node_modules/@mui/material/index";
import { useAxios } from "../../hooks/auth";
import { pulldownsPostData } from "../../axios/api";
import PulldownAutoselection from "./PulldownAutoselection";

function InsurancePulldown({
  formState,
  setFormState = () => {},
  setIsSaveButtonDisabled = () => {},
  updateInput,
  labelTitle,
  insuranceId,
  insuranceName,
  isReadOnly = false,
  isPatientPortal,
  insurances
}) {
  
  return (
    <>
      {insurances?.length && (
        <FormControl
          variant="standard"
          sx={{ m: 1, minWidth: 120, width: "90%" }}
        >
          {/* <PulldownAutoselection
            options={insurances?.map((item) => item.planname)}
            optionsForId={insurances?.map((item) => {
              return { name: item.planname, id: item.insuranceId };
            })}
            label={labelTitle}
            formState={formState}
            setFormState={setFormState}
            setIsSaveButtonDisabled={setIsSaveButtonDisabled}
            attributeName={insuranceName}
            attributeId={insuranceId}
            isReadOnly={
              isReadOnly === true ? true : isPatientPortal ? true : false
            }
          /> */}
          
          <InputLabel id="insurance-label">{labelTitle}</InputLabel>
            <Select
              labelId="insurance-label"
              id={insuranceId}
              name={insuranceId}
              value={formState[insuranceId] || ''}
              label="insurance"
              onChange={updateInput}
              inputProps={
                { readOnly:  isReadOnly === true ? true : isPatientPortal ? true : false }
              }
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {insurances?.map((insurance) => (
                <MenuItem value={insurance.insuranceId} key={insurance.insuranceId}>{insurance.planname}</MenuItem>
              ))}
            </Select>
        </FormControl>
      )}
    </>
  );
}

export default InsurancePulldown;
