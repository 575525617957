import moment from "moment";

function signOrderHTMLString({
  signature,
  formState,
  visitDateState,
  fallsAsleepCheckBox,
  inssuranceData,
  physicianName,
  selectedCheckbox,
  headerDetail,
  headerLogo,
  downloadOrder,
  action
}) {
  let signOrderDate = new Date();
  signOrderDate = moment(signOrderDate).format("MM/DD/YYYY");
  const visitDate =
    visitDateState && moment.utc(new Date(visitDateState)).format("MM/DD/YYYY");

  let currentDateTime = new Date().toLocaleString();
  const dynamicHTML = headerDetail && headerDetail[1].map((detail) => {
    return `• ${detail?.location}`;
  }).join(' ');
  const htmlString = `
    <!DOCTYPE html>
  <html>
  <head>
    <meta charset="UTF-8">
    <title>My A4 Page</title>
  </head>
  <style>
  div.left {
    float: left;
    clear: both;
}
</style>
  <body style="margin: 0; padding: 0; box-sizing: border-box; display:flex; justify-content :center; align-items:center;">
    <div style="width: 770px; height: 100%; padding: 20px; font-family: Arial, sans-serif; font-size: 16px; line-height: 1.4; margin :0px 100px">
      <div style="display: flex; flex-direction: column; width: 770px; align-items: center; justify-content: center;">
      <div style="display: flex;align-items: center; justify-content: center;">
           ${ downloadOrder ? `<div style="font-weight: bold;">ORDER FORM</div>`:
           `${headerDetail && headerDetail[0][0]?.logoFileId !== null
            ? `<img src=${headerLogo} style="width: 700px; height: 150px;" alt="signed"/>`
            :`<div style="font-weight: bold;">SLEEP STUDY ORDER FORM</div>`}
          </div>
          ${headerDetail && headerDetail[0][0]?.logoFileId !== null || headerDetail[0][0]?.groupHeaderSleepStudy === 1?
          `<div style="font-size: 18px; width: 770px; text-align: center; margin-top: 8px">
            ${dynamicHTML}
            </div>`
            :
          `<div style="font-size: 12px; width: 770px; text-align: center">
             ${headerDetail[1][0]?.address1} ${headerDetail[1][0]?.address2} • ${headerDetail[1][0]?.city} ${headerDetail[1][0]?.state} • ${headerDetail[1][0]?.zip} OFFICE ${headerDetail[1][0]?.phone}
             • FAX ${headerDetail[1][0]?.fax}
          </div>`}` }  
          </div>
          <div style="width: 770px; border: 2px solid black;">
              <div style="display: flex; align-items: center; justify-content: space-between; ">
                  <div style="padding: 10px;">
                      <div style="font-size: 12px;">PATIENT'S FULL NAME (required)</div>
                      <div style="display: flex; align-items: center; justify-content: space-between; gap :20px">
                          <div>
                              <div style="padding: 5px; border: 1px solid black; width: 190px;">${
                                formState.firstName || ""
                              }</div>
                              <div class="lower_lable" style="font-size: 10px;">First</div>
                          </div>
                          
                          <div>
                              <div style="padding: 5px; border: 1px solid black; width: 60px;"></div>
                              <div class="lower_lable" style="font-size: 10px;">MI</div>
                          </div>
                          
                          <div >
                              <div style="padding: 5px; border: 1px solid black ; width: 190px;">${
                                formState.lastName || ""
                              }</div>
                              <div class="lower_lable" style="font-size: 10px;">Last</div>
                          </div>
                      </div>
                  </div>
                  <div class="patients_DOB" style="border-left: 2px solid black;">
                      <div style="padding: 10px;">
                          <div style="font-size: 12px;">PATIENT'S DATE OF BIRTH (required)</div>
                          <div style="display: flex; align-items: center;">
                              <div>
                                  <div style="padding: 5px; border: 1px solid black; width: 190px;">${
                                    formState.dob || ""
                                  }</div>
                                  <div class="lower_lable" style="font-size: 10px;">Month/Day/Year</div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div style="width: 770px; border: 2px solid black;">
            <div style="display: flex; align-items: center; justify-content: space-between;">
                <div>
                    <div style="display: flex; align-items: center; justify-content: space-between; width: 770px;">
                        <div class="patients_DOB">
                            <div style="padding: 10px;">
                                <div style="font-size: 12px;">PATIENT'S BEST CONTACT NUMBER</div>
                                <div style="display: flex; align-items: center;">
                                    <div style = "width: 100%;">
                                        <div style="padding: 5px; border: 1px solid black;  width: 200px; ">${
                                          formState.bestPhone || ""
                                        }</div>
                                        <div class="lower_lable" style="font-size: 10px;">AREA CODE AND NUMBER</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="patients_DOB" style="border-left: 2px solid black;">
                            <div style="padding: 10px;">
                                <div style="font-size: 12px;">PATIENT'S CELLULAR NUMBER</div>
                                <div style="display: flex; align-items: center;">
                                    <div>
                                        <div style="padding: 5px; border: 1px solid black;  width: 200px; min-height : 20px;">${
                                          formState.cellPhone || ""
                                        }</div>
                                        <div class="lower_lable" style="font-size: 10px;">AREA CODE AND NUMBER</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="patients_DOB" style="border-left: 2px solid black;">
                            <div style="padding: 10px;">
                                <div style="font-size: 12px;">PATIENT'S EMAIL ADDRESS</div>
                                <div style="display: flex; align-items: center;">
                                    <div>
                                        <div style="padding: 5px; border: 1px solid black;  min-width: 200px; min-height: 20px; word-break: break-all;">${
                                          formState.email || ""
                                        }</div>
                                        <div class="lower_lable" style="font-size: 10px;"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div style="width: 770px; border: 2px solid black; display: flex; align-items: center; justify-content: space-between;">
             <div style="width: 360px; padding: 10px; border-right: 2px solid; ">
                <div style="font-size: 12px;">PATIENT'S INSURANCE CARRIER</div>
                <div style="display: flex; align-items: center; justify-content: space-between;">
                    <div class="patients_DOB">
                        <div>
                            <!-- <div style="font-size: 12px;">PATIENT'S INSURANCE CARRIER</div> -->
                            <div style="display: flex; align-items: center;">
                                <div>
                                    <div style="padding: 5px; border: 1px solid black; width: 160px; min-height: 20px;">${
                                      inssuranceData?.primaryInsurance || ""
                                    }</div>
                                    <div class="lower_lable" style="font-size: 10px;">PRIMARY</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="patients_DOB">
                        <div>
                            <!-- <div style="font-size: 12px;">PATIENT'S DATE OF BIRTH (required)</div> -->
                            <div style="display: flex; align-items: center;">
                                <div>
                                    <div style="padding: 5px; border: 1px solid black; width: 160px; min-height: 20px;">${
                                      inssuranceData?.secondaryInsurance || ""
                                    }</div>
                                    <div class="lower_lable" style="font-size: 10px;">SECONDARY</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           
             </div>
             <div style="width: 360px; padding: 10px; ">
                <div style="font-size: 12px;">PATIENT'S INSURANCE CARRIER</div>
                <div style="display: flex; align-items: center; justify-content: space-between;">
                    <div class="patients_DOB">
                        <div>
                            <!-- <div style="font-size: 12px;">PATIENT'S INSURANCE CARRIER</div> -->
                            <div style="display: flex; align-items: center;">
                                <div>
                                    <div style="padding: 5px; border: 1px solid black; width: 160px; min-height: 20px;">${
                                      formState?.primaryPolicy || ""
                                    } ${formState?.primaryGroup ? "/" : ""} ${
    formState?.primaryGroup || ""
  } </div>
                                    <div class="lower_lable" style="font-size: 10px;">Primary Policy and Group #</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="patients_DOB">
                        <div>
                            <!-- <div style="font-size: 12px;">PATIENT'S DATE OF BIRTH (required)</div> -->
                            <div style="display: flex; align-items: center;">
                                <div>
                                    <div style="padding: 5px; border: 1px solid black; width: 160px; min-height: 20px;">${
                                      formState?.secondaryPolicy || ""
                                    } ${formState?.secondaryGroup ? "/" : ""}${
    formState?.secondaryGroup || ""
  }</div>
                                    <div class="lower_lable" style="font-size: 10px;">Secondary Policy And Group #</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           
             </div>
          </div>

          <div style="border: 2px solid black; width: 770px;">
            <div style="padding: 10px; font-size: 14px; font-weight: bold; display: flex; align-items: center; justify-content: space-between;">
                <div>
                    FACE TO FACE NOTES ( all that apply)
                </div>
                <div style="min-hight:20px">
                   <span style="margin-right: 10px;" > <span style="text-decoration: underline;">  DATE OF VISIT </span>(required) </span> ${
                     visitDate || ""
                   }
                </div>
            </div>
            <div style="padding: 10px; font-size: 12px; padding-top: 0px;">
            Snoring  ${!formState?.snoring ? "[ ]" : "[X]"}
             Fatigue ${!formState?.fatigue ? "[ ]" : "[X]"}
             Excessive Daytime Sleepiness ${
               !formState?.daytimeSleepiness ? "[ ]" : "[X]"
             }
             Wakes up gasping for breath  ${
               !formState?.gasping ? "[ ]" : "[X]"
             } 
            Witnessed Apneas Excessive Daytime Sleepiness ${
              !formState?.apneas ? "[ ]" : "[X]"
            }
            Falls asleep at inappropriate times and/or places ${
              !formState?.inappropriateSleep ? "[ ]" : "[X]"
            }
            Other ${!formState?.otherFaceNotes ? "[ ] " : "[X] "}
            <span style="text-decoration: underline; font-weight: normal"> ${
              formState?.otherNotes || ""
            } </span>
            </div>
          </div>

          <div style="width: 770px; display: flex; justify-content: center; border: 2px solid black;">
                <div style="width: 360px; border-right: 0.5px solid black;">
                    <div style="font-size: 12px; font-weight: bold; margin-left: 3px;">PLEASE CHOOSE ONE STUDY TYPE (required)</div>
                    <div style="display: flex; align-items: flex-start; margin-top: 10px;">
                        <input type="checkbox" ${
                          selectedCheckbox === "2nightProtocol" && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">2 NIGHT PROTOCOL - 1ST Polysomnogram (PSG) THEN 2nd night CPAP trial, if indicated (95810/95811)</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-left: 30px;">
                        <input type="checkbox"  ${
                          formState?.["2nightHSTSubstitute"] && "checked"
                        }  style="width: 17px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px; text-decoration: underline;">SUBSTITUTE HST IF IN LAB DISQUALIFIED BY INSURANCE POLICY OR DENIED</div>
                    </div>

                    <div style="display: flex; align-items: flex-start;">
                        <input type="checkbox" ${
                          selectedCheckbox === "splitNightProtocol" && "checked"
                        }  style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">  SPLIT NIGHT PROTOCOL - 1ST night SPLIT - if PAP not initiated, THEN 2nd night CPAP/BiPAP (95810/95811)</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-left: 30px;">
                        <input type="checkbox" ${
                          formState?.splitHSTSubstitute && "checked"
                        } style="width: 17px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px; text-decoration: underline;">SUBSTITUTE HST IF IN LAB DISQUALIFIED BY INSURANCE POLICY OR DENIED</div>
                    </div>

                    <div style="display: flex; align-items: flex-start;">
                        <input type="checkbox" ${
                          selectedCheckbox === "psgBaselineStudy" && "checked"
                        }  style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">PSG baseline study (95810)</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-left: 30px;">
                        <input type="checkbox" ${
                          formState?.psgOptions ===
                            "SUBSTITUTE HST if in-lab disqualified by insurance or denied OR," &&
                          "checked"
                        } style="width: 17px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px; text-decoration: underline;">
                        SUBSTITUTE HST IF IN LAB DISQUALIFIED BY INSURANCE POLICY OR DENIED OR,</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-left: 30px;">
                        <input type="checkbox" ${
                          formState?.psgOptions ===
                            "PSG with MSLT (Multiple Sleep Latency Test) 95805" &&
                          "checked"
                        } style="width: 17px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                            PSG with MSLT (Multiple Sleep Latency Test) 95805</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-left: 30px;">
                        <input type="checkbox" ${
                          formState?.psgOptions ===
                            "PSG with MWT (Maintenance of Wakefulness Test) 95805" &&
                          "checked"
                        } style="width: 17px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                            PSG with MWT (Maintenance of Wakefulness Test) 95805</div>
                    </div>

                    <div style="display: flex; align-items: flex-start;">
                        <input type="checkbox" ${
                          selectedCheckbox === "homeSleepTest" && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                            HOME SLEEP TEST</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-left: 30px;">
                        <div style="display: flex; align-items: center; ">
                            <input type="checkbox" ${
                              formState?.hstNights === "ONE NIGHT" && "checked"
                            } style="width: 17px; height: 20px;">
                            <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                                ONE NIGHT</div>
                        </div>
                        

                        <div style="display: flex; align-items: center; ">
                        <input type="checkbox" ${
                          formState?.hstNights === "TWO NIGHT" && "checked"
                        } style="width: 17px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                            TWO NIGHT</div>
                            </div>

                        <div style="display: flex; align-items: center; ">
                        <input type="checkbox" ${
                          formState?.hstNights === "THREE NIGHT" && "checked"
                        } style="width: 17px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                             THREE NIGHT</div>
                             </div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-left: 30px;">
                        <input type="checkbox" ${
                          formState?.cpapIfHstAbnormal === 1 && "checked"
                        } style="width: 17px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                           IF abnormal HST  then CPAP titration (95811)</div>
                    </div>

                    <div style="display: flex; align-items: flex-start;">
                        <input type="checkbox" ${
                          selectedCheckbox === "cpapTitration" && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                            CPAP titration (95811)</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-left: 30px;">
                        <input type="checkbox" ${
                          formState?.cpapOptions ===
                            "MSLT (Multiple Sleep Latency Test) 95805" &&
                          "checked"
                        }  style="width: 17px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                           MSLT (Multiple Sleep Latency Test) 95805</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-left: 30px;">
                        <input type="checkbox" ${
                          formState?.cpapOptions ===
                            "MWT (Maintenance of Wakefulness Test) 95805" &&
                          "checked"
                        } style="width: 17px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                           MWT (Maintenance of Wakefulness Test) 95805</div>
                    </div>

                    <div style="display: flex; align-items: flex-start;">
                        <input type="checkbox" ${
                          selectedCheckbox === "bipapTitration" && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                            BiPAP titration (95811)</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-left: 30px;">
                        <input type="checkbox" ${
                          formState?.bipapOptions ===
                            "MSLT (Multiple Sleep Latency Test) 95805" &&
                          "checked"
                        } style="width: 17px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                           MSLT (Multiple Sleep Latency Test) 95805</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-left: 30px;">
                        <input type="checkbox" ${
                          formState?.bipapOptions ===
                            "MWT (Maintenance of Wakefulness Test) 95805" &&
                          "checked"
                        } style="width: 17px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                           MWT (Maintenance of Wakefulness Test) 95805</div>
                    </div>

                    <div style="display: flex; align-items: flex-start;">
                        <input type="checkbox" ${
                          selectedCheckbox === "bipapStTitration" && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                            BiPAP ST titration (95811)</div>
                    </div>
                    <div style="display: flex; align-items: flex-start;">
                        <input type="checkbox" ${
                          selectedCheckbox === "AsvTitration" && "checked"
                        }  style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                            ASV titration (95811)</div>
                    </div>
                    <div style="display: flex; align-items: flex-start;">
                        <input type="checkbox" ${
                          selectedCheckbox === "AvapsTitration" && "checked"
                        }  style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                            AVAPS titration (95811)</div>
                    </div>
                    <div style="display: flex; align-items: flex-start;">
                        <input type="checkbox" ${
                          selectedCheckbox === "pediatricSleepStudy" &&
                          "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                            PEDIATRICS SLEEP STUDY (5 YEARS OF AGE OR YOUNGER)</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-left: 30px;">
                        <input type="checkbox"  ${
                          formState?.pediatricOptions ===
                            "PSG BASELINE STUDY (95782 or 95810 Medicaid only)" &&
                          "checked"
                        } style="width: 17px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                           PSG BASELINE STUDY (95782 or 95810 Medicaid only)</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-left: 30px;">
                        <input type="checkbox"  ${
                          formState?.pediatricOptions ===
                            "CPAP titration (95783 or 95811 Medicaid only)" &&
                          "checked"
                        } style="width: 17px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                           CPAP titration (95783 or 95811 Medicaid only)</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-left: 30px;">
                        <input type="checkbox"  ${
                          formState?.pediatricOptions ===
                            "BiPAP titration (95783 or 95811 Medicaid only)" &&
                          "checked"
                        } style="width: 17px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                           BiPAP titration (95783 or 95811 Medicaid only)</div>
                    </div>

                </div>
                <div style="width: 360px; border-left: 0.5px solid black;">
                    <div style="font-size: 12px; font-weight: bold; margin-left: 3px;">PLEASE CHOOSE AT LEAST ONE ICD-10 DX DIAGNOSIS CODE (required)</div>
                    <div style="display: flex; align-items: flex-start; margin-top: 10px;">
                        <input type="checkbox" ${
                          formState.G47_30 && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">G47.30 Sleep apnea, unspecified</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-top: 3px;">
                        <input type="checkbox" ${
                          formState.G47_33 && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">G47.33 Obstructive sleep apnea</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-top: 3px;">
                        <input type="checkbox" ${
                          formState.G47_31 && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">G47.31 Primary Central Sleep Apnea</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-top: 3px;">
                        <input type="checkbox" ${
                          formState.G47_9 && "checked"
                        }  style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">G47.9 Sleep disorder, unspecified</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-top: 3px;">
                        <input type="checkbox" ${
                          formState.E66_2 && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">E66.2 Morbid (severe) obesity alveolar hypoventilation</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-top: 3px;">
                        <input type="checkbox" ${
                          formState.G47_69 && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">G47.69 Other sleep related movements</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-top: 3px;">
                        <input type="checkbox" ${
                          formState.G47_61 && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">G47.61 Periodic limb movement disorders</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-top: 3px;">
                        <input type="checkbox" ${
                          formState.F51_12 && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">F51.12 Insufficient sleep syndrome</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-top: 3px;">
                        <input type="checkbox" ${
                          formState.G47_11 && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">G47.11 Idiopathic hypersomnia with long sleep time</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-top: 3px;">
                        <input type="checkbox" ${
                          formState?.G47_12 && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">G47.12 Idiopathic hypersomnia without long sleep time</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-top: 3px;">
                        <input type="checkbox" ${
                          formState?.G47_13 && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">G47.13 Recurrent hypersomnia </div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-top: 3px;">
                        <input type="checkbox" ${
                          formState?.G47_19 && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">G47.19 Other hypersomnia </div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-top: 3px;">
                        <input type="checkbox" ${
                          formState?.F51_4 && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">F51.4 Sleep terrors (night terrors)</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-top: 3px;">
                        <input type="checkbox" ${
                          formState?.F51_5 && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">F51.5 Nightmare disorders</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-top: 3px;">
                        <input type="checkbox" ${
                          formState?.F51_3 && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">F51.3 Sleepwalking (Somnambulism)</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-top: 3px;">
                        <input type="checkbox" ${
                          formState?.G47_411 && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">G47.411 Narcolepsy with cataplexy</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-top: 3px;">
                        <input type="checkbox" ${
                          formState?.G47_419 && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">G47.419 Narcolepsy without cataplexy</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-top: 3px;">
                        <input type="checkbox" ${
                          formState?.G47_421 && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">G47.421 Narcolepsy in conditions classified elsewhere, with
                            cataplexy</div>
                    </div>
                    <div style="display: flex; align-items: flex-start; margin-top: 3px;">
                        <input type="checkbox" ${
                          formState?.G47_421_wo_cataplexy && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">G47.421 Narcolepsy in conditions classified elsewhere,
                            without cataplexy</div>
                    </div>

                    <div style="display: flex; align-items: flex-start; margin-top: 20px; border: 2px solid black; margin-bottom: 20px;">
                        <input type="checkbox" ${
                          formState?.consult && "checked"
                        } style="width: 31px; height: 20px;">
                        <div style="font-size: 12px; margin-left: 3px; margin-top: 3px;">
                            CONSULT AND TREAT BY BOARD CERTIFIED SLEEP PHYSICIAN</div>
                    </div>

                </div>
          </div>

          <div style="border: 2px solid black;  width: 770px;">
            <div style="font-size: 12px; padding: 10px; padding-top: 5px;">SPECIAL/ADDITIONAL INSTRUCTIONS:  ${
              formState?.addlInstructions || ""
            } </div>
           
          </div>

          <div style="width: 770px; border: 2px solid black; display: flex; align-items: center; justify-content: space-between;">
            <div style="width: 380px; display: flex; align-items: center; justify-content: space-between; border-right: 2px solid black;">
               <div class="patients_DOB" style=" width:100%;">
                   <div style="padding: 10px;">
                       <div style="font-size: 12px; font-weight: bold;">PROVIDER/PROVIDER EXTENDER’S PRINTED NAME (required)</div>
                       <div style="display: flex; align-items: center;">
                           <div>
                               <div style="padding: 5px; border: 1px solid black; width: 300px;></div>
                               ${ action ?  ` <div class="lower_lable" style="font-size: 10px; height:20px">${
                                physicianName}</div>`:` <div class="lower_lable" style="font-size: 10px; height:20px">${
                                physicianName?.firstName
                              }  ${physicianName?.lastName}</div>`}
                           </div>
                       </div>
                   </div>
               </div>
            </div>
            <div style="width: 360px; display: flex; justify-content: space-between; align-self: flex-start;">
               <div class="patients_DOB">
                   <div style="padding: 10px;">
                       <div style="font-size: 12px; font-weight: bold;">SIGNATURE (required)</div>
                       <div style="display: flex; align-items: center;">
                           <div>
                               <div style="padding: 5px; border: 1px solid black; width: 300px; border-left: 0; border-right: 0; border-top: 0; margin-top: 15px;">
                               ${ !action ? ` <div class="left" id="l1"><img src=${signature} style="width: 50px; height: 50px;" alt="signed"/></div>` : ` <div class="left" id="l1"><img src='' style="width: 50px; height: 50px;" alt="signed"/></div>` }
                              
                            </div>
                            ${ action ? `` : downloadOrder ? `  <div class="lower_lable" style="font-size: 10px;"> Electronically Signed by ${physicianName} at ${currentDateTime} </div> `
      : `  <div class="lower_lable" style="font-size: 10px;"> Electronically Signed by ${ physicianName?.firstName }  ${physicianName?.lastName} at ${currentDateTime} </div> ` }
                          
                           </div>
                       </div>
                   </div>
               </div>
            </div>
         </div>

         <div style="width: 770px; border: 2px solid black; display: flex; align-items: center; justify-content: space-between;">
            <div style="width: 400px; padding: 10px; border-right: 2px solid black;">
                <div style="font-size: 12px; font-weight: bold;">FOR TIMELY PATIENT CARE, PLEASE SEND A COPY OF PTS DEMOGRAPHICS,
                    INSURANCE CARD/INFO, AND CURRENT CLINICAL NOTES. <span style="font-size: 14px; font-weight: bold;"> THANK YOU.</span></div>
            </div>
            <div style="width: 269px; display: flex; align-items: center; justify-content: space-between; padding: 10px;">
                <div style="font-size: 12px; font-weight: bold;">RX DATE:(required)</div>
                <div style="font-size: 14px;">${signOrderDate}</div>
            </div>
         </div>
         </div>
  </body>
  </html>
    `;
  return htmlString;
}

export default signOrderHTMLString;
