import * as React from "react";

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "../../../node_modules/@mui/material/index";

const PhysicianAuthorityPulldown = ({
    formState = {},
    updateInput,
    formErrorState = {},
    attributeName,
    selectedAuthorizedAttributeNames,
    index,
    authorizedList,
    authorizedUserData,
    setAuthorizedList = () => { },
    setHidePulldown = () => { },
    ...remaining
}) => {
    return (
        <>
            <FormControl
                variant="standard"
                sx={{ m: 1, width: "90%", minWidth: "120px" }}
                {...remaining}
            >
                <InputLabel
                    id="authorized-label"
                    error={formErrorState[attributeName]?.error && !formState[attributeName]}
                >
                    Authorised Users
                </InputLabel>
                <Select
                    labelId="authorized-label"
                    data-testid={`authorized-pulldown-${index}`}
                    id={attributeName}
                    name={attributeName}
                    value={formState[attributeName] || ""}
                    label="authorized"
                    onChange={(e) => updateInput(e, authorizedList)}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {authorizedUserData
                        .filter((authorizedFilter) => {
                            return (
                                !selectedAuthorizedAttributeNames ||
                                selectedAuthorizedAttributeNames.every(
                                    (attr) =>
                                        String(formState[attr]) !==
                                        String(authorizedFilter.userId)
                                )
                            );
                        })
                        .map((item) => (
                            <MenuItem value={item.userId} key={item.userId}>
                                {item.lastName} {item.firstName}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </>
    )
}

export default PhysicianAuthorityPulldown