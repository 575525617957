import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import * as React from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { useAuth, useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "./Dialog";
import {
  FormControl,
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Dialog,
  useMediaQuery,
  Grid,
  Button,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import GeneralPulldown from "./pulldowns/GeneralPulldown";
import LocationPulldown from "./LocationPulldown";
import PrefInterpPhysicianPulldown from "./PrefInterpPhysicianPulldown";
import PhysicianPulldown from "./PhysicianPulldown";
import StudyTypePulldown from "./StudyTypePulldown";
import { studyPostData, pulldownsPostData, dmeStudyPostData } from "../../axios/api";
import ReschedulePopup from "./ReschedulePopup";

export default function RescheduleCancelDialog({
  handleSave,
  handleCloseSchedule,
  row,
  setViewCloseDialogState = () => {},
  notificationToastSuccess,
  notificationToastError,
  isPreviousVisits = false,
  isPatientPortal,
  rescheduleData,
  asDialog = true,
  notShowPopup,
  isWellnecessitiesUser
}) {
  const [formErrorState, setFormErrorState] = useState({});
  const { cookies } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const axios = useAxios();
  const paramNameIn = "name";
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  // console.log("row = " , row);

  React.useEffect(() => {
    if (row) {
      const newFormState = {
        physicianId: row?.physicianIdOrder,
        patientId: row?.patientId,
        surveyId: row?.surveyIdSurvey,
        orderId: row?.orderIdOrder,
        stageId: row?.stageId,
        prefInterpPhysId: row?.prefInterpPhysId,
        date: row?.date,
        studyTypeId: row?.studyTypeId,
        locationId: row?.locationIdStudy,
        SHIP: row?.shipDate,
        trackingNum: row?.trackingNum,
        studyId: row?.studyIdStudy,
        time: row?.time,
        reasonReschedulingId: row?.reasonReschedulingId,
        reasonCancelingId: row?.reasonCancelingId,
        hstDeviceId: row?.hstDeviceId,
        reasonAuthDenial: row?.reasonAuthorDenial,
        deviceReturnDate: row?.deviceReturnDate,
        requestedReschedule: row?.requestedReschedule,
        primaryInsuranceId: row?.primaryInsuranceIdP,
        secondaryInsuranceId: row?.secondaryInsuranceIdP,
        primaryPolicy: row?.primaryPolicyP,
        primaryGroup: row?.primaryGroupP,
        secondaryPolicy: row?.secondaryPolicyP,
        secondaryGroup: row?.secondaryGroupP,
        requestedCancelStudy: row?.requestedCancelStudy,
        pickup:row?.pickup,
        outTrackingNum: row?.outTrackingNum,
        dmeOrderId: row?.dmeOrderIdOrder,
        shipDate:row?.shipDate,
      };

      setFormState(newFormState);
      setDeviceReturnDate(row?.deviceReturnDate);
  
      setSHIP(row?.shipDate);
      if (newFormState.studyTypeId && newFormState.prefInterpPhysId)
        ScheduleLocation(newFormState);
    } else if (rescheduleData) {
      setFormState({ ...formState, ...rescheduleData });
      setDeviceReturnDate(rescheduleData?.deviceReturnDate);
      if (rescheduleData.studyTypeId && rescheduleData.prefInterpPhysId)
        ScheduleLocation(rescheduleData);
    }
  }, [row]);

  function getErrorValidationProperty(key) {
    return {
      ...(formState[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }

  //update device return due date
  const updateDueDate = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setDeviceReturnDate(newValue.format("MM/DD/YYYY"));
    setFormState({
      ...formState,
      deviceReturnDate: newValue.format("YYYY-MM-DD"),
    });
  };
  const updateCheckBox = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: Number(e.target.checked),
    });
    setIsSaveButtonDisabled(false);
  };
  const [deviceReturnDate, setDeviceReturnDate] = useState(null);
  const [deviceReturnDateErr, setDeviceReturnDateErr] = useState(null);
  const [locations, setLocations] = useState([]);

  const handleMainCheckboxChange = (event) => {
    const checkboxId = event.target.id;
    formState["requestedReschedule"] = 0;
    formState["requestedCancelStudy"] = 0;

    formState[checkboxId] = event.target.checked ? 1 : 0;
    setFormState({ ...formState });
    setIsSaveButtonDisabled(false);
  };

  const updateInput = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    });
  };

  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key)) {
        isValid = false;
        errors[key] = { error: true };
      }
    }

    if (
      !formState.reasonReschedulingId &&
      (formState?.requestedReschedule === "1" ||
        formState?.requestedReschedule === 1 ||
        formState.requestedCancelStudy == null ||
        formState?.requestedCancelStudy == "0" ||
        formState?.requestedCancelStudy == 0)
    ) {
      isValid = false;
      formErrorState["reasonReschedulingId"] = { error: true };
    }
    if (
      !formState.reasonCancelingId &&
      (formState?.requestedCancelStudy === "1" || formState?.requestedCancelStudy === 1)
    ) {
      isValid = false;
      formErrorState["reasonCancelingId"] = { error: true };
    }

    if (!isValid) {
      setErrorMessage("Please fill all required fields");
      setFormErrorState(formErrorState);

      //setFormErrorState(errors);
      return false;
    }
    saveStudy(formState);
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  function ScheduleLocation(payload) {
    pulldownsPostData(axios, "scheduleLocationPulldown", payload)
      .then((res) => {
        if (res && res.data) setLocations(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const saveStudy = () => {
    setOpen(true);
    setIsSaveButtonDisabled(true);
    const payload = {
      ...formState,
      rescheduleData: undefined,
      shipDate: SHIP || formState?.SHIP || formState?.shipDate,
    };
    if (formState?.dmeOrderId > 0 && !formState?.orderId) {
      dmeStudyPostData(axios, "saveStudy", payload)
        .then((res) => {
          if (res?.data?.response === "1") {
            notificationToastSuccess(res?.data?.message);
          } else {
            notificationToastError(res?.data?.message);
          }
        })
        .catch((err) => {
          notificationToastError(err?.message);
        });
      notificationToastSuccess("success");
      setTimeout(() => {
        setViewCloseDialogState(false);
      }, 2000);
    } else {
      studyPostData(axios, "saveStudy", payload)
        .then((res) => {
          if (res?.data?.response === "1") {
            notificationToastSuccess(res?.data?.message);
          } else {
            notificationToastError(res?.data?.message);
          }
        })
        .catch((err) => {
          notificationToastError(err?.message);
        });
      notificationToastSuccess("success");
      setTimeout(() => {
        setViewCloseDialogState(false);
      }, 2000);
    }
  };
  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  //pickup handlechange
  const pickupCheckboxChange = (event) => {
    const checkboxId = event.target.id;

    formState[checkboxId] = event.target.checked ? 1 : 0;
    setFormState({ ...formState });
    setIsSaveButtonDisabled(false);
  };

  const [SHIP, setSHIP] = React.useState(null);
  const [errorSHIP, setErrorSHIP] = React.useState(false);
  const updateSHIP = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setSHIP(newValue.format("YYYY-MM-DD"));
  };



  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleCloseSchedule}
        confirmButton={{ action: saveChanges, title: "Save" }}
        isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }
  function renderForm(asDialog) {
    return (
      <>
        <div>
          {" "}
          <FormControl sx={{ mt: 2, mb: 2 }}>
            <Typography variant="h6">
            Request Reschedule / Cancel Appointment
:{" "}
            </Typography>
          </FormControl>
        </div>

        {asDialog && !notShowPopup && <ReschedulePopup row={row} />}
        <Box sx={{ marginTop: "1rem" }}>
          {formState?.reasonAuthDenial !== 0 &&
            formState?.reasonAuthDenial !== null && (
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <TextField
                  id="reasonAuthDenial"
                  label="Authorization Denied"
                  variant="standard"
                  value={formState?.reasonAuthDenial || ""}
                  readOnly
                  InputProps={{ disableUnderline: true }}
                />
              </FormControl>
            )}
        </Box>
        <Box sx={{ marginTop: "1rem" }}>
          <FormControlLabel
            control={
              <Checkbox
                id="requestedReschedule"
                checked={Boolean(formState.requestedReschedule)}
                onChange={handleMainCheckboxChange}
              />
            }
            label="Request Reschedule Appointment"
          />

          <FormControlLabel
            control={
              <Checkbox
                id="requestedCancelStudy"
                checked={Boolean(formState.requestedCancelStudy)}
                onChange={handleMainCheckboxChange}
              />
            }
            label="Request Cancel Appointment"
          />
        </Box>


     {formState?.requestedReschedule == 1 && (
          <>
            <GeneralPulldown
              key="reasonReschedulingId"
              isPreviousVisits={isPreviousVisits}
              formState={formState}
              updateInput={updateInput}
              formErrorState={formErrorState}
              attributeName="reasonReschedulingId"
              style={{ width: "100%" }}
              isPatientPortal={isPatientPortal}
              pulldownApiName="ReasonApptReschedulingPulldown"
              itemName="reason"
              labelName="Select Reason For Rescheduling"
            />
          </>
        )}


        {formState?.requestedCancelStudy == 1 && (
          <>
            <GeneralPulldown
              key="reasonCancelingId"
              isPreviousVisits={isPreviousVisits}
              formState={formState}
              updateInput={updateInput}
              formErrorState={formErrorState}
              attributeName="reasonCancelingId"
              style={{ width: "100%" }}
              isPatientPortal={isPatientPortal}
              pulldownApiName="ReasonApptCancelingPulldown"
              itemName="reason"
              labelName="Select Reason For Canceling"
            />
          </>
        )}

            
        {!asDialog && !isPreviousVisits && isWellnecessitiesUser && (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              disabled={isSaveButtonDisabled}
              sx={{ margin: "1rem 0rem" }}
              onClick={saveChanges}
              data-testid="schedule-save-Button"
            >
              Save Changes
            </Button>
          </div>
        )}
      </>
    );
  }
}
