import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "../../hooks/auth";
import { useAxios } from "../../hooks/auth";
import { updateSelectedPatient } from "../../store/slices/selectedPatientSlice";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { patientsPaginationGetData } from "../../axios/api";
import MUIDataTable from "mui-datatables";
import dayjs from "dayjs";
import { notificationToastError } from "../../utils/notification";
import { patientsPostData } from "../../axios/api";
import { dateConverter } from "../../utils/dateConverter";

export function SearchPatient({
  setIsSelectPatient = true,
  isSelectPatient = true,
  selectedPatient,
}) {
  const isMobile = useMediaQuery("(max-width:800px)");
  const navigate = useNavigate();
  const axios = useAxios();
  const [refreshData, setRefreshData] = useState(false);
  const [viewPatientFoundState, setVewPatientFoundState] = useState(false);
  const [formState, setFormState] = useState({});
  const [rowData, setRowData] = useState([]);
  const [isUserSearch, setIsUserSearch] = useState(false);
  React.useEffect(() => {
    if (selectedPatient?.dob) setDOB(selectedPatient.dob);
  }, [selectedPatient?.dob]);

  const handleClickOpen = () => {};

  const handlePatientsView = async () => {
    const paramsName = "patientView";
    const payload = {
      patientId: selectedPatient?.patientId,
    };
    localStorage.removeItem('patientId');
    patientsPaginationGetData(axios, paramsName, payload)
      .then((res) => {
        setRowData(res);
        navigate(`/patient-view`, { state: { patientInfo: res[0] } });
      })
      .catch((err) => notificationToastError(err?.message));
  };

  const handleClear = () => {
    dispatch(updateSelectedPatient({...selectedPatient, patientId: "",
    patientNum: "",
    firstName: "",
    lastName: "",
    dob: null,}));
    setVewPatientFoundState(false);
    setFormState({
      ...formState,
      patientId: "",
      patientNum: "",
      firstName: "",
      lastName: "",
      dob: null,
    });
    setDOB(null);
    setRefreshData(!refreshData);
    setIsUserSearch(false);
    localStorage.removeItem('patientId')
    // navigate("/patients");
  };

  function searchPatient() {
    const payload = {
      patientNum: formState.patientNum,
      firstName: formState.firstName,
      lastName: formState.lastName,
      dob: DOB?.format("YYYY-MM-DD"),
    };
    patientsPostData(axios, "searchPatient", payload)
      .then((res) => {
        if (res.data) {
          setVewPatientFoundState(true);
          setIsUserSearch(true);
          setRowData(res.data);
        }
      })
      .catch((err) => notificationToastError(err.message));
  }
  const dispatch = useDispatch();
  const [DOB, setDOB] = React.useState(null);

  const handlePatient = (isSearchOnadd, dataOnadd) => {
    const date = new Date(DOB);
    if (isUserSearch || isSearchOnadd) {
      if (
        (formState.firstName || formState?.lastName || DOB) &&
        (isUserSearch || isSearchOnadd) &&
        !(
          formState.firstName &&
          formState?.lastName &&
          DOB &&
          (rowData?.length || dataOnadd?.length)
        )
      ) {
        navigate("/patient-view", {
          state: {
            ...((formState.firstName || formState?.lastName || DOB) &&
              (isUserSearch || isSearchOnadd) &&
              !(
                formState.firstName &&
                formState?.lastName &&
                DOB &&
                (rowData?.length || dataOnadd?.length)
              ) && {
                searchPatientInfo: {
                  ...formState,
                  dob: DOB && dateConverter(date),
                  addNewPatient : true
                },
              }),
            nav: "addPatient",
          },
        });
      } else {
        notificationToastError(
          "Patient already exist in system, select it from below table"
        );
      }
    } else {
      navigate("/patient-view", {
        state: {
          ...{
            searchPatientInfo: {
              ...formState,
              dob: DOB && dateConverter(date),
              addNewPatient : true
            },
          },
          nav: "addPatient",
        },
      });
    }
  };
  const addPatient = () => {
    if (!isUserSearch && (formState?.firstName || formState?.lastName || DOB)) {
      const payload = {
        patientNum: formState.patientNum,
        firstName: formState.firstName,
        lastName: formState.lastName,
        dob: DOB?.format("YYYY-MM-DD"),
      };
      let isSearchOnadd = false;
      patientsPostData(axios, "searchPatient", payload)
        .then((res) => {
          setVewPatientFoundState(true);
          if (res.data?.length) {

            isSearchOnadd = true;
            setRowData(res.data);
            if (isSearchOnadd) {
              searchPatient();
              //  handlePatient(isSearchOnadd,res?.data)
            }
          } else {
            handlePatient(isSearchOnadd, res?.data);
          }
        })
        .catch((err) => notificationToastError(err.message));
    } else {
      const date = new Date(DOB);
      navigate("/patient-view", {
        state: {
          ...{
            searchPatientInfo: {
              ...formState,
              dob: DOB && dateConverter(date),
              addNewPatient : true
            },
          },
          nav: "addPatient",
        },
      });
    }

    //  handlePatient()

    // if (isUserSearch || isSearchOnadd) {
    //   if (
    //     (formState.firstName || formState?.lastName || DOB) &&
    //     (isUserSearch || isSearchOnadd) &&
    //     !(formState.firstName && formState?.lastName && DOB && rowData?.length)
    //   ) {
    //     navigate("/patient-view", {
    //       state: {
    //         ...((formState.firstName || formState?.lastName || DOB) &&
    //           (isUserSearch || isSearchOnadd) &&
    //           !(
    //             formState.firstName &&
    //             formState?.lastName &&
    //             DOB &&
    //             rowData?.length
    //           ) && {
    //             searchPatientInfo: {
    //               ...formState,
    //               dob: DOB && dateConverter(date),
    //             },
    //           }),
    //         nav: "addPatient",
    //       },
    //     });
    //   } else {
    //     notificationToastError(
    //       "Patient already exist in system, select it from below table"
    //     );
    //   }
    // } else {
    //   navigate("/patient-view", {
    //     state: {
    //       ...{
    //         searchPatientInfo: {
    //           ...formState,
    //           dob: DOB && dateConverter(date),
    //         },
    //       },
    //       nav: "addPatient",
    //     },
    //   });
    // }
  };

  const updateDOB = (newValue) => {
    if (isUserSearch) {
      setIsUserSearch(false);
    }
    setDOB(newValue);
    setFormState({ ...formState, dob: newValue });
  };

  const updateInput = (e) => {
    if (isUserSearch) {
      setIsUserSearch(false);
    }
    setFormState({ ...formState, [e.target.id]: e.target.value });
  };

  return (
    <Card sx={{ minWidth: 275, width: "100%", marginBottom: "20px" }}>
      <CardContent
        sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
      >
        <Typography variant="h6">Search and Select Patient</Typography>

        <Box sx={{ textAlign: "center" }}>
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
              gap: "1rem",
            }}
          >
            <Box
              sx={{
                display: isMobile ? "grid" : "flex",
                gridTemplateColumns: isMobile ? "1fr 1fr" : "0fr 0fr",
                gap: "1rem",
              }}
            >
              <TextField
                id="lastName"
                label="Last Name:"
                value={formState.lastName || selectedPatient?.lastName || ""}
                variant="standard"
                onChange={updateInput}
              />{" "}
              <TextField
                id="firstName"
                label="First Name:"
                value={formState.firstName || selectedPatient?.firstName || ""}
                variant="standard"
                onChange={updateInput}
              />
              <TextField
                id="patientNum"
                label="Patient ID:"
                sx={{
                  maxWidth: "5rem",
                }}
                value={
                  formState.patientNum || selectedPatient?.patientNum || ""
                }
                variant="standard"
                onChange={updateInput}
              />{" "}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="DOB:"
                  inputFormat="MM/DD/YYYY"
                  value={DOB}
                  onChange={updateDOB}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                  )}
                />
              </LocalizationProvider>
              <CardActions>
                {!selectedPatient?.patientId && (
                  <>
                    <Button
                      variant="contained"
                      onClick={searchPatient}
                      size="small"
                    >
                      Search
                    </Button>
                    <Button
                      sx={{ minWidth: "110px" }}
                      variant="contained"
                      onClick={addPatient}
                      size="small"
                    >
                      Add Patient
                    </Button>
                  </>
                )}
                <Button variant="contained" onClick={handleClear} size="small">
                  Clear
                </Button>

                {selectedPatient?.patientId && (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handlePatientsView}
                  >
                    View
                  </Button>
                )}
              </CardActions>
            </Box>
          </Box>

          {viewPatientFoundState && (
            <Box sx={{ marginTop: "1rem" }}>
              <MUIDataTable
                data={rowData}
                columns={[
                  {
                    name: "patientId",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },
                  {
                    name: "patientNum",
                    label: "Patient ID",
                  },
                  {
                    name: "lastName",
                    label: "Last name",
                  },
                  {
                    name: "firstName",
                    label: "First Name",
                  },
                  {
                    name: "dob",
                    label: "DOB",
                    options: {
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return <>{dayjs(value).format("MM/DD/YYYY")}</>;
                      },
                    },
                  },
                  {
                    name: "groupName",
                    label: "Group",
                  },
                  {
                    name: "createdAt",
                    label: "Created at",
                  },
                  {
                    name: "middleInitial",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },
                  {
                    name: "gender",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },
                  {
                    name: "email",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },
                  {
                    name: "bestPhone",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },
                  {
                    name: "cellPhone",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },
                  {
                    name: "primaryInsuranceId",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },

                  {
                    name: "secondaryInsuranceId",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },

                  {
                    name: "primaryPolicy",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },

                  {
                    name: "primaryGroup",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },

                  {
                    name: "secondaryPolicy",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },

                  {
                    name: "secondaryGroup",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },
                  {
                    name: "address1",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },

                  {
                    name: "address2",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },

                  {
                    name: "city",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },

                  {
                    name: "state",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },

                  {
                    name: "zip",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },

                  {
                    name: "heightFeet",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },//23
                  {
                    name: "heightInches",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },//24
                  {
                    name: "patWeight",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },//25
                  {
                    name: "patBMI",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },//26
                  {
                    name: "race",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },//27
                  {
                    name: "maritalStatus",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },//28
                  {
                    name: "prefLanguage",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },//29
                  {
                    name: "placeofemp",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },//30
                  {
                    name: "ssn",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },//31
                  {
                    name: "enablePortal",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },//32
                  {
                    name: "tertiaryInsuranceId",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },//33
                  {
                    name: "tertiaryPolicy",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },//34
                  {
                    name: "tertiaryGroup",
                    options: {
                      display: false,
                      viewColumns: false,
                      filter: false,
                    },
                  },//35
                ]}
                options={{
                  responsive: "vertical",
                  download: false,
                  filter: false,
                  print: false,
                  search: false,
                  viewColumns: false,
                  selectableRows: 'none', // <===== will turn off checkboxes in rows
                  onRowClick: (row) => {
                    const data = [...row];
                    data[4] = data[4]?.props?.children;

                    setFormState({
                      ...formState,
                      firstName: data[3],
                      lastName: data[2],
                      patientId: data[0],
                      patientNum: data[1],
                      dob: data[4],
                      middleInitial: data[7],
                      gender: data[8],
                      email: data[9],
                      bestPhone: data[10],
                      cellPhone: data[11],
                    });
                    setVewPatientFoundState(false);
                    dispatch(
                      updateSelectedPatient({
                        firstName: data[3],
                        lastName: data[2],
                        patientId: data[0],
                        patientNum: data[1],
                        dob: data[4],
                        middleInitial: data[7],
                        gender: data[8],
                        email: data[9],
                        bestPhone: data[10],
                        cellPhone: data[11],
                        primaryInsuranceId: data[12],
                        secondaryInsuranceId: data[13],
                        primaryPolicy: data[14],
                        primaryGroup: data[15],
                        secondaryPolicy: data[16],
                        secondaryGroup: data[17],
                        address1: data[18],
                        address2: data[19],
                        city: data[20],
                        state: data[21],
                        zip: data[22],
                        heightFeet: data[23],
                        heightInches: data[24],
                        patWeight: data[25],
                        patBMI: data[26],
                        race: data[27],
                        maritalStatus: data[28],
                        prefLanguage: data[29],
                        placeofemp: data[30],
                        ssn: data[31],
                        enablePortal: data[32],
                        tertiaryInsuranceId: data[33],
                        tertiaryPolicy: data[34],
                        tertiaryGroup: data[35],
                      })
                    );
                  },
                }}
              />
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
