import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import * as React from "react";

import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Alert,
} from "../../../node_modules/@mui/material/index";
import { useAxios } from "../../hooks/auth";
import { pulldownsPostData } from "../../axios/api";

function PrefInterpPhysicianPulldown({
  formState,
  updateInput,
  formErrorState,
  attributeName,
  isPreviousVisits = false,
  isPatientPortal,
  physicianId,
  width,
  label
}) {
  const [prefInterpPhysicians, setPrefInterpPhysicians] = useState([]);
  const axios = useAxios();
  useEffect(() => {
    async function setDataFirstTime() {
      let payload 
      if (isPatientPortal) {
        payload = {
          patientPortal: 1,
        };
      } else {
        payload = {
          physicianId: physicianId
        };

      }

      pulldownsPostData(axios, "prefInterpPhysicianPulldown", payload)
        .then((res) => {
          if (res?.data) {
            setPrefInterpPhysicians(res?.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setDataFirstTime();
  }, [isPatientPortal, physicianId]);

  return (
    <>
      <FormControl variant="standard" sx={{ width: width ? width : "100%", mt: 1, mb: 1 }}>
        <InputLabel id="user-label">
          {label ? label : "Select Preferred Interpreting Physician "}
        </InputLabel>
        <Select
          labelId="user-label"
          id={attributeName}
          name={attributeName}
          value={formState[attributeName] || ""}
          label="Preferred Interpreting Physician"
          onChange={(e)=>updateInput(e)}
          inputProps={{ readOnly: isPreviousVisits }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {prefInterpPhysicians.map((prefInterpPhysician) => (
            <MenuItem value={prefInterpPhysician.userId} key={prefInterpPhysician.userId}>
              {prefInterpPhysician.lastName}, {prefInterpPhysician.firstName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export default PrefInterpPhysicianPulldown;
