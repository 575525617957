import TextField from "@mui/material/TextField";
import { useState } from "react";
import * as React from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { useAuth, useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "./Dialog";
import ReschedulePopup from "./ReschedulePopup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import LocationPulldown from "./LocationPulldown";
import PrefInterpPhysicianPulldown from "./PrefInterpPhysicianPulldown";
import PhysicianPulldown from "./PhysicianPulldown";
import StudyTypePulldown from "./StudyTypePulldown";
import { dmeStudyPostData, getInfoData } from "../../axios/api";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Typography,
  FormGroup,
  Divider,
} from "@mui/material";

export default function DmeVerifyInsuranceDialog({
  handleSave,
  handleClose,
  row,
  setViewCloseDialogState = () => {},
  notificationToastSuccess = () => {},
  notificationToastError = () => {},
  asDialog = true,
  isPreviousVisits = false,
  inssuranceData = {},
  notShowPopup,
  reloadCurrentVisit,
  isWellnecessitiesUser
}) {
  // console.log("isPreviousVisits==>",isPreviousVisits);
  const [formErrorState, setFormErrorState] = useState({});
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  // const { cookies } = useAuth();
  // const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const axios = useAxios();
  const paramNameIn = "name";
  const updateCheckBox = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: Number(e.target.checked),
    });
  };

  let patientNameVal;
  if (row) {
    patientNameVal = row?.patientName;
  }else{
    patientNameVal = inssuranceData.patientNameVal
  }

  React.useEffect(() => {
    if (row) {
      console.log('DME row = ', row)
      let authReq ;
      if (row?.inLabAuthRequired && row?.inLab)  authReq = 1 ; else if (row?.hstAuthRequired && row?.hst) authReq = 1 ;

      setFormState({
        physicianId: row?.physicianIdOrder,
        patientId: row?.patientId,
        dob: row?.dob,
        surveyId: row?.surveyIdSurvey,
        orderId: row?.orderIdOrder,
        dmeOrderId: row?.dmeOrderId ,
        email: row?.email,
        cellPhone: row?.email,
        stageId: row?.stageId,
        statusNameId: row?.statusNameId,
        prefInterpPhysId: row?.prefInterpPhysId,
        studyId: row?.studyIdStudy || row?.studyId,
        primaryInsuranceId: row?.primaryInsuranceIdP,
        planNameP: row?.planNameP,
        primaryPolicy: row?.primaryPolicyP,
        primaryGroup: row?.primaryGroupP,
        secondaryInsuranceId: row?.secondaryInsuranceIdP,
        planNameS: row?.planNameS,
        secondaryPolicy: row?.secondaryPolicyP,
        secondaryGroup: row?.secondaryGroupP,
        date: row?.date,
        studyTypeName: row?.studyType,
        apptLocationName: row?.locationStudy,
        npi: row?.npi,
        taxId: row?.taxId,
        applyDeductible: row?.applyDeductible,
        claimAddress: row?.claimAddress,
        coIns: row?.coIns,
        copay: row?.copay,
        cptsVerified: row?.cptsVerified,
        deductible: row?.deductible,
        deductibleMet: row?.deductibleMet,
        deductibleYear: row?.deductibleYear,
        inNetwork: row?.inNetwork,
        insVerified: row?.insVerified,
        insVerifiedDate: row?.insVerifiedDate,
        oop: row?.oop,
        oopMet: row?.oopMet,
        parContact: row?.parContact,
        placeOfService: row?.placeOfService,
        planAdmin: row?.planAdmin,
        planType: row?.planType,
        policyEffDate: row?.policyEffDate ? row?.policyEffDate : `01/01/${new Date().getFullYear()}`,
        policyHolder: row?.policyHolder,
        policyHolderDob: row?.policyHolderDob ? moment(row?.policyHolderDob).format("MM/DD/YYYY") : "",
        policyType: row?.policyType,
        priorAuthRequired: row?.priorAuthRequired || authReq,
        referenceNum: row?.referenceNum,
        referralRequired: row?.referralRequired,
        referralRequiredFrom: row?.referralRequiredFrom,
        spokeTo: row?.spokeTo,
        verifiedContact: row?.verifiedContact,
        VDATE: row?.insVerifiedDate,
        PDATE: row?.policyHolderDob ? moment(row?.policyHolderDob).format("MM/DD/YYYY") : `01/01/${new Date().getFullYear()}`,
        EDATE: row?.policyEffDate,
        //secondary field
        policyEffDate2: row?.policyEffDate2 ? moment(row?.policyEffDate2).format("YYYY-MM-DD") : `01/01/${new Date().getFullYear()}`,
        deductible2: row?.deductible2,
        deductibleMet2: row?.deductibleMet2,
        oop2: row?.oop2,
        oopMet2: row?.oopMet2,
        coIns2: row?.coIns2,
        copay2: row?.copay2,
        inNetwork2: row?.inNetwork2,
        applyDeductible2:row?.applyDeductible2,
        deductibleYear2: row?.deductibleYear2,
        meditechAcctNum: row?.meditechAcctNum,
        verifiedPrimary:row?.verifiedPrimary,
        verifiedSecondary:row?.verifiedSecondary,
        policySelf:row?.policySelf,
        npi2: row?.npi2,
        taxId2: row?.taxId2,
        planNameT: row?.planNameT,
        tertiaryPolicy: row?.tertiaryPolicyP,
        tertiaryGroup: row?.tertiaryGroupP,
        tertiaryInsuranceId: row?.tertiaryInsuranceIdP,
        //tertiary field
        policyEffDate3: row?.policyEffDate3 ? moment(row?.policyEffDate3).format("YYYY-MM-DD") : `01/01/${new Date().getFullYear()}`,
        deductible3: row?.deductible3,
        deductibleMet3: row?.deductibleMet3,
        oop3: row?.oop3,
        oopMet3: row?.oopMet3,
        coIns3: row?.coIns3,
        copay3: row?.copay3,
        inNetwork3: row?.inNetwork3,
        applyDeductible3:row?.applyDeductible3,
        deductibleYear3: row?.deductibleYear3,
        verifiedTertiary:row?.verifiedTertiary,
        priorAuthRequired2: row?.priorAuthRequired2,
        priorAuthRequired3: row?.priorAuthRequired3,
        policyType2: row?.policyType2,
        planAdmin2: row?.planAdmin2,
        spokeTo2: row?.spokeTo2,
        referenceNum2: row?.referenceNum2,
        policyType3: row?.policyType3,
        planAdmin3: row?.planAdmin3,
        spokeTo3: row?.spokeTo3,
        referenceNum3: row?.referenceNum3,
      });
      if (row?.primaryInsuranceIdP && !row?.taxId) {
        let payload = {};
        payload = {
          insuranceId: row?.primaryInsuranceIdP,
        };

        getInfoData(axios, "taxNpi", payload)
        .then((res) => {
          if (res?.length) {
            const insData = res[0];
            const taxId = insData?.taxId ;
            const npi = insData?.npi ;
        
            setFormState((prevState) => ({
              ...prevState,
              npi,
              taxId
            }));
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {});


      }

      if (row?.secondaryInsuranceIdP && !row?.taxId2) {
        let payload = {};
        payload = {
          insuranceId: row?.secondaryInsuranceIdP,
        };

        getInfoData(axios, "taxNpi", payload)
        .then((res) => {
          if (res?.length) {
            const insData = res[0];
            const taxId2 = insData?.taxId ;
            const npi2 = insData?.npi ;
        
            setFormState((prevState) => ({
              ...prevState,
              npi2,
              taxId2
            }));
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {});


      }


    } else if (inssuranceData) {
      setFormState({ ...formState, ...inssuranceData });
      setVDATE(inssuranceData?.VDATE);
      setEDATE(inssuranceData?.EDATE);
      setPDATE(inssuranceData?.PDATE);
    }
  }, [row]);

 
  // if(formState.policySelf === "Self"){
  //   setPDATE(formState.dob)
  // }else{
  //   setPDATE(inssuranceData?.PDATE);
  // }

  const updateInput = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    });
  };

  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key)) {
        isValid = false;
        errors[key] = { error: true };
      }
    }

    if (!isValid) {
      setFormErrorState(errors);
      return false;
    }
    saveStudy(formState);
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const saveStudy = () => {
    setOpen(true);
    const errors = { ...formErrorState };
    let isValid = true;

    if (formState.insVerified == 1 && !VDATE && !formState.VDATE) {
      isValid = false;
      setErrorVDATE(true);
    }


    if (!isValid) {
      setErrorMessage("Please fill all required fields");
      setFormErrorState(errors);
      return false;
    } else {
      setErrorMessage("");
    }
  
    const payload = {
      ...formState,
      patientName: patientNameVal,
      insVerifiedDate: formState.VDATE ,
      policyHolderDob: formState.PDATE ? moment(formState.PDATE).format("YYYY-MM-DD") : "",
      PDATE: moment(formState.PDATE).format("YYYY-MM-DD") ,
      policySelf: formState.policySelf,
      policyEffDate: formState.policyEffDate ? moment(formState.policyEffDate).format("YYYY-MM-DD") :null,
      policyEffDate2: formState.policyEffDate2 ? moment(formState.policyEffDate2).format("YYYY-MM-DD") :null ,
      policyEffDate3: formState.policyEffDate3 ? moment(formState.policyEffDate3).format("YYYY-MM-DD") :null ,
    };
    setIsSaveButtonDisabled(true);
    dmeStudyPostData(axios, "verifyInsuranceSave", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
          notificationToastSuccess("success");
          setTimeout(() => {
            setViewCloseDialogState(false);
          }, 2000);
          if (reloadCurrentVisit) reloadCurrentVisit(payload);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });

  };
  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const [PDATE, setPDATE] = React.useState(null);
  const [errorPDATE, setErrorPDATE] = React.useState(false);
  const updatePDATE = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    //    newValue && setPDATE(newValue.format("YYYY-MM-DD"));
    setPDATE(newValue.format("MM/DD/YYYY"));
    setFormState({ ...formState, PDATE: newValue.format("YYYY-MM-DD") });
  };

  const [EDATE, setEDATE] = React.useState(null);
  const [errorEDATE, setErrorEDATE] = React.useState(false);
  const updateEDATE = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setEDATE(newValue.format("MM/DD/YYYY"));
    setFormState({ ...formState, EDATE: newValue.format("YYYY-MM-DD") });
  };

  const [VDATE, setVDATE] = React.useState(null);
  const [errorVDATE, setErrorVDATE] = React.useState(false);

  const updateVDATE = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }

    setVDATE(newValue.format("MM/DD/YYYY"));
    setFormState({ ...formState, VDATE: newValue.format("YYYY-MM-DD") });
  };


  //handle change for primary,secondary and tertiary
  const handleChangeIns = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setFormState({
      ...formState,
      [e.target.id]:Number(e.target.checked)
    })
   
  } 

  // handle change for insurance date
  const updateInsuranceData = (newValue,type) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setFormState({ ...formState, [type]: newValue.format("YYYY-MM-DD") });
  }

  //handle change for insurance field 
  const updateInputInsurance = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }

    const { value, id } = e.target

    setFormState({ ...formState, [id]:value });
  }

  //handle change for in network insurance
  const updateInsuranceCheckBox = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }

    setFormState({ ...formState, [e.target.id]: Number(e.target.checked) });
  }

  //handle change for insurance verified
  const updateVerifiedCheckBox = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
   
    if(e.target.checked){
      setFormState({
        ...formState,
        [e.target.id || e.target.name]: Number(e.target.checked),
        VDATE: moment(new Date().toLocaleString()).format("YYYY-MM-DD")
      });
      setVDATE(moment(new Date().toLocaleString()).format("MM/DD/YYYY"));
    }else{
      setFormState({
        ...formState,
        [e.target.id || e.target.name]: Number(e.target.checked),
        VDATE: ""
      });
      setVDATE("");
    }
  }
  //handle self policy value
  const updateInputPolicySelf = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
      policyHolder: e.target.value === "Self" ? patientNameVal : formState.policyHolder,
      policyHolderDob: e.target.value === "Self" ? (formState.dob || PDATE ) : formState.PDATE,
      PDATE: e.target.value === "Self" ? (formState.dob || PDATE || "") : formState.PDATE
    })
    setPDATE(formState.dob || PDATE)
  };
  

  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleClose}
        confirmButton={{ action: saveChanges, title: "Save" }}
        isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }
  function renderForm(asDialog) {
    return (
      <Box data-testid="verification-Dialog-render">
        <div>
          <FormControl sx={{ margin: "1rem 0rem" }}>
            <Typography variant="h6" data-testid="verification-title-text">
              {" "}
              {!asDialog
                ? "Insurance And Electronic Verification Prior Authorization Info : "
                : "Please Enter Insurance Verification Info :"}{" "}
            </Typography>
          </FormControl>
        </div>
        {asDialog && !notShowPopup && (
            <ReschedulePopup row={row} />
        )}

        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            gap: 1,
            marginTop: 1,
          }}
        >
          <Typography variant="strong"> Primary Insurance:</Typography>
        </Box>
        <Box
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            gap: 1,
            marginTop: 1,
          }}
        >
          <TextField
            id="planNameP"
            label="Plan Name"
            variant="standard"
            value={formState.planNameP || formState.primaryInsurance || ""}
            readOnly
            InputProps={{ disableUnderline: true }}
          />
          <TextField
            id="primaryPolicy"
            label="Policy ID"
            variant="standard"
            value={formState.primaryPolicy || ""}
            readOnly
            InputProps={{ disableUnderline: true }}
          />
          <TextField
            id="primaryGroup"
            label="Group"
            variant="standard"
            value={formState.primaryGroup || ""}
            readOnly
            InputProps={{ disableUnderline: true }}
          />
        </Box>
<Box>
        <TextField
                id="taxId"
                label="Tax ID"
                variant="standard"
                value={formState.taxId || ""}
                readOnly
                InputProps={{ disableUnderline: true }}
              />
              <TextField
                id="npi"
                label="NPI"
                variant="standard"
                value={formState.npi || ""}
                readOnly
                InputProps={{ disableUnderline: true }}
              />
              </Box>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            gap: 1,
            marginTop: 1,
          }}
        >
          <Typography variant="strong"> Secondary Insurance: </Typography>
        </Box>
        <Box
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            gap: 1,
            marginTop: 1,
          }}
        >
          <TextField
            id="planNameS"
            label="Plan Name"
            variant="standard"
            value={formState.planNameS || formState.secondaryInsurance || ""}
            readOnly
            InputProps={{ disableUnderline: true }}
          />
          <TextField
            id="secondaryPolicy"
            label="Policy ID"
            variant="standard"
            value={formState.secondaryPolicy || ""}
            readOnly
            InputProps={{ disableUnderline: true }}
          />
          <TextField
            id="secondaryGroup"
            label="Group"
            variant="standard"
            value={formState.secondaryGroup || ""}
            readOnly
            InputProps={{ disableUnderline: true }}
          />
        </Box>
        {/* tertiary plan */}
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            gap: 1,
            marginTop: 1,
          }}
        >
          <Typography variant="strong"> Tertiary Insurance: </Typography>
        </Box>
        <Box
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            gap: 1,
            marginTop: 1,
          }}
        >
          <TextField
            id="planNameT"
            label="Plan Name"
            variant="standard"
            value={formState.planNameT || ""}
            readOnly
            InputProps={{ disableUnderline: true }}
          />
          <TextField
            id="tertiaryPolicy"
            label="Policy ID"
            variant="standard"
            value={formState.tertiaryPolicy || ""}
            readOnly
            InputProps={{ disableUnderline: true }}
          />
          <TextField
            id="tertiaryGroup"
            label="Group"
            variant="standard"
            value={formState.tertiaryGroup || ""}
            readOnly
            InputProps={{ disableUnderline: true }}
          />
        </Box>
        <Box>
        <TextField
                id="taxId2"
                label="Tax ID"
                variant="standard"
                value={formState.taxId2 || ""}
                readOnly
                InputProps={{ disableUnderline: true }}
              />
              <TextField
                id="npi2"
                label="NPI"
                variant="standard"
                value={formState.npi2 || ""}
                readOnly
                InputProps={{ disableUnderline: true }}
              />
              </Box>
        {asDialog && (
          <>
            <Box
              component="div"
              sx={{
                "& .MuiFormControl-root": { flexGrow: 1 },
                display: "flex",
                gap: 1,
                marginTop: 1,
              }}
            >
              <Typography variant="strong">Appointment Info: </Typography>
            </Box>
            <Box
              sx={{
                "& .MuiFormControl-root": { flexGrow: 1 },
                display: "flex",
                gap: 1,
                marginTop: 1,
              }}
            >
              <TextField
                id="date"
                label="Appt Date"
                variant="standard"
                value={formState.date || ""}
                readOnly
                InputProps={{ disableUnderline: true }}
              />
              <TextField
                id="studyTypeName"
                label="Study Type"
                variant="standard"
                value={formState.studyTypeName || ""}
                readOnly
                InputProps={{ disableUnderline: true }}
              />
            </Box>
            <Box
              sx={{
                "& .MuiFormControl-root": { flexGrow: 1 },
                display: "flex",
                gap: 1,
              }}
            >
              <TextField
                id="apptLocationName"
                label="Facility (Appt location)"
                variant="standard"
                value={formState.apptLocationName || ""}
                readOnly
                InputProps={{ disableUnderline: true }}
              />
             
            </Box>
          </>
        )}

        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1},
            display: "flex",
            gap: 1,
            marginTop: 2,
            alignItems: "baseline"
          }}
        >
          <FormControl variant="standard" sx={{ width: 250, marginTop: 3 }}>
            <InputLabel id="gender-label">Place of Service Requested:</InputLabel>

            <Select
              labelId="contact-label"
              id="placeOfService"
              name="placeOfService"
              value={formState.placeOfService || ""}
              label="Place of Service Requested"
              onChange={updateInput}
              inputProps={{ readOnly: isPreviousVisits }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Home">Home</MenuItem>
              <MenuItem value="Out Patient">Out Patient</MenuItem>
              <MenuItem value="IDTF">IDTF</MenuItem>
            </Select>
          </FormControl>

          <TextField
            id="meditechAcctNum"
            label="Billing ACCT#"
            variant="standard"
            onChange={updateInput}
            value={formState.meditechAcctNum || ""}
            inputProps={{ readOnly: isPreviousVisits }}
            onMouseDown={handleFocus}
          />
        </Box>

        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            gap: 1,
            marginTop: 2,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                id="insVerified"
                checked={Boolean(formState.insVerified)}
                onChange={isPreviousVisits ? () => {} : updateVerifiedCheckBox}
                readOnly={true}
              />
            }
            label="Insurance Verified"
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Date Verified"
              inputFormat="MM/DD/YYYY"
              value={VDATE || formState.VDATE || ""}
              onChange={updateVDATE}
              readOnly={isPreviousVisits}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  error={errorVDATE && !VDATE}
                  helperText={errorVDATE && !VDATE ? "required" : ""}
                  value={
                    //VDATE ? moment(new Date(VDATE)).format("MM/DD/YYYY") : ""
                    VDATE
                      ? moment.utc(VDATE.toLocaleString()).format("MM/DD/YYYY")
                      : ""
                  }
                  onBlur={() => {
                    setErrorVDATE(!VDATE);
                  }}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                  placeholder="mm/dd/yyyy"
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            gap: 1,
            marginTop: 2,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                id="cptsVerified"
                checked={Boolean(formState.cptsVerified)}
                onChange={isPreviousVisits ? () => {} : updateCheckBox}
              />
            }
            label="CPT/HCPC Codes Verified"
          />
          <TextField
            id="verifiedContact"
            label="Verification Contact #"
            variant="standard"
            onChange={updateInput}
            value={formState.verifiedContact || ""}
            inputProps={{ readOnly: isPreviousVisits }}
            onMouseDown={handleFocus}
          />
        </Box>
        
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            gap: 1,
            marginTop: 2,
          }}
        >
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue=""
            name="policySelf"
            id="policySelf"
            value={formState.policySelf || ""}
            // onChange={isPreviousVisits ? () => {} : updateInput}
            onClick={(e) => {
              if (e.target.value === formState?.policySelf) {
                setFormState({
                  ...formState,
                  policySelf: "",
                  policyHolder: row?.policyHolder || inssuranceData.policyHolder || "",
                  policyHolderDob:row?.policyHolderDob ? moment(row?.policyHolderDob).format("MM/DD/YYYY") : inssuranceData.PDATE ?  moment(inssuranceData.PDATE).format("MM/DD/YYYY") : "",
                  PDATE: row?.policyHolderDob ? moment(row?.policyHolderDob).format("MM/DD/YYYY") : inssuranceData.PDATE ?  moment(inssuranceData.PDATE).format("MM/DD/YYYY") : ""
                });
                setPDATE("")
              } else updateInputPolicySelf(e);
            }}
          >
            <FormControlLabel
              value="Self"
              control={<Radio />}
              label="Self"
            />
          </RadioGroup>
          {/* {
            formState.policySelf == "Self" && */}
            <>
              <TextField
                id="policyHolder"
                label="Policy Holder Name"
                variant="standard"
                onChange={updateInput}
                value={formState.policyHolder || ""}
                inputProps={{ readOnly: isPreviousVisits }}
                onMouseDown={handleFocus}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Policy Holder DOB"
                  inputFormat="MM/DD/YYYY"
                  value={PDATE || formState.PDATE || formState.policyHolderDob ||  ""}
                  onChange={updatePDATE}
                  readOnly={isPreviousVisits}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      error={errorPDATE && !PDATE}
                      helperText={errorPDATE && !PDATE ? "required" : ""}
                      value={
                        // formState.policySelf === "Self" ? (formState.dob || formState.PDATE || "") : (PDATE
                        //   ? moment.utc(PDATE.toLocaleString()).format("MM/DD/YYYY")
                        //   : formState.PDATE || "")
                        // formState.policySelf === "Self" ? moment.utc( formState.dob.toLocaleString()).format("MM/DD/YYYY") : moment.utc(formState.PDATE.toLocaleString()).format("MM/DD/YYYY")
                        PDATE
                          ? moment.utc(PDATE.toLocaleString()).format("MM/DD/YYYY") || ""
                          : formState.PDATE || ""}
                      inputProps={{ readOnly: isPreviousVisits }}
                      onMouseDown={handleFocus}
                      placeholder="mm/dd/yyyy"
                    />
                  )}
                />
              </LocalizationProvider>
            </>
          {/* } */}
          
        </Box>
        <Divider sx={{ mt: 2 , border:"2px solid rgba(0, 0, 0, 0.12)"}} />
        <Box
          component="div"
          sx={{
            // "& .MuiFormControl-root": { flexGrow: 1 },
            // display: "flex",
            // gap: 1,
            marginTop: 1,
          }}
        >
          <FormGroup>
            <FormControlLabel control={<Checkbox id="verifiedPrimary" checked={Boolean(formState.verifiedPrimary)} onChange={(e) => handleChangeIns(e)} />} label="Primary" />
            {Boolean(formState.verifiedPrimary) && <> <Box
              component="div"
              sx={{
                "& .MuiFormControl-root": { flexGrow: 1 },
                display: "flex",
                gap: 1,
                marginTop: 2,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Eff. date of policy"
                  inputFormat="MM/DD/YYYY"
                  value={EDATE || formState.policyEffDate || ""}
                  onChange={(e)=>updateInsuranceData(e,"policyEffDate")}
                  readOnly={isPreviousVisits}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      error={errorEDATE && !formState.policyEffDate}
                      helperText={errorEDATE && !formState.policyEffDate ? "required" : ""}
                      value={
                        formState.policyEffDate ? moment.utc(formState.policyEffDate.toLocaleString()).format("MM/DD/YYYY") : ""
                      }
                      inputProps={{ readOnly: isPreviousVisits }}
                      onMouseDown={handleFocus}
                      placeholder="mm/dd/yyyy"
                    />
                  )}
                />
              </LocalizationProvider>

              <TextField
                id="deductible"
                label="$ Deductible Amount"
                variant="standard"
                onChange={(e)=>updateInputInsurance(e)}
                value={formState.deductible || ""}
                inputProps={{ readOnly: isPreviousVisits }}
                onMouseDown={handleFocus}
              />
            </Box>
              <Box
                component="div"
                sx={{
                  "& .MuiFormControl-root": { flexGrow: 1 },
                  display: "flex",
                  gap: 1,
                  marginTop: 2,
                }}
              >
                <TextField
                  id="deductibleMet"
                  label="$ Deductible Amount Met"
                  variant="standard"
                  onChange={(e)=>updateInputInsurance(e)}
                  value={formState.deductibleMet || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />

                <TextField
                  id="oop"
                  label="$ Out of Pocket Amount"
                  variant="standard"
                  onChange={(e)=>updateInputInsurance(e)}
                  value={formState.oop || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />
                <TextField
                  id="oopMet"
                  label="$ OOP Amount Met"
                  variant="standard"
                  onChange={(e)=>updateInputInsurance(e)}
                  value={formState.oopMet || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />
              </Box>
              <Box
                component="div"
                sx={{
                  "& .MuiFormControl-root": { flexGrow: 1 },
                  display: "flex",
                  gap: 1,
                  marginTop: 2,
                }}
              >
                <TextField
                  id="coIns"
                  label="CoInsurance"
                  variant="standard"
                  onChange={(e)=>updateInputInsurance(e)}
                  value={formState.coIns || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />

                <TextField
                  id="copay"
                  label="$ Copay"
                  variant="standard"
                  onChange={(e)=>updateInputInsurance(e)}
                  value={formState.copay || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      id="inNetwork"
                      checked={Boolean(formState.inNetwork)}
                      onChange={isPreviousVisits ? () => { } : (e)=>updateInsuranceCheckBox(e)}
                    />
                  }
                  label="Is plan in network?"
                />
              </Box>
              <Box
                component="div"
                sx={{
                  "& .MuiFormControl-root": { flexGrow: 1 },
                  display: "flex",
                  gap: 1,
                  marginTop: 2,
                }}
              >
                <TextField
                  id="policyType"
                  label="Policy Type"
                  variant="standard"
                  onChange={updateInput}
                  value={formState.policyType || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />
                <TextField
                  id="planAdmin"
                  label="Plan Administrator"
                  variant="standard"
                  onChange={updateInput}
                  value={formState.planAdmin || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />
                <TextField
                  id="spokeTo"
                  label="Spoke to"
                  variant="standard"
                  onChange={updateInput}
                  value={formState.spokeTo || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />

              </Box>
              <Box
                component="div"
                sx={{
                  marginTop: 2,
                }}
              >
                <TextField
                  id="referenceNum"
                  label="Reference #"
                  variant="standard"
                  onChange={updateInput}
                  value={formState.referenceNum || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />
              </Box>
              <Box
                component="div"
                sx={{
                  "& .MuiFormControl-root": { flexGrow: 1 },
                  display: "flex",
                  gap: 1,
                  marginTop: 2,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      id="applyDeductible"
                      checked={Boolean(formState.applyDeductible)}
                      onChange={isPreviousVisits ? () => { } : updateInsuranceCheckBox}
                    />
                  }
                  label="Does Deductible Apply "
                />

                <TextField
                  id="deductibleYear"
                  label="Deductible Calendar Year?"
                  variant="standard"
                  onChange={updateInputInsurance}
                  value={formState.deductibleYear || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
            
                
                />
              </Box>

              <Box
                component="div"
                sx={{
                  "& .MuiFormControl-root": { flexGrow: 1 },
                  display: "flex",
                  gap: 1,
                  marginTop: 2,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      id="priorAuthRequired"
                      checked={Boolean(formState.priorAuthRequired)}
                      onChange={isPreviousVisits ? () => { } : updateCheckBox}
                    />
                  }
                  label="PRIOR AUTH REQUIRED?"
                />
              </Box>
              
              </>}
            <Divider sx={{ mt: 2 , border:"2px solid rgba(0, 0, 0, 0.12)"}} />

            <FormControlLabel sx={{ marginTop: 1 }} control={<Checkbox id="verifiedSecondary" checked={Boolean(formState.verifiedSecondary)} onChange={(e) => handleChangeIns(e)} />} label="Secondary" />
            {Boolean(formState.verifiedSecondary) && <> <Box
              component="div"
              sx={{
                "& .MuiFormControl-root": { flexGrow: 1 },
                display: "flex",
                gap: 1,
                marginTop: 2,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Eff. date of policy"
                  inputFormat="MM/DD/YYYY"
                  value={formState.policyEffDate2 || ""}
                  onChange={(e)=>updateInsuranceData(e,"policyEffDate2")}
                  readOnly={isPreviousVisits}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      error={errorEDATE && !formState.policyEffDate2}
                      helperText={errorEDATE && !formState.policyEffDate2 ? "required" : ""}
                      value={
                        formState.policyEffDate2 ? moment.utc(formState.policyEffDate2.toLocaleString()).format("MM/DD/YYYY") : ""
                      }
                      inputProps={{ readOnly: isPreviousVisits }}
                      onMouseDown={handleFocus}
                      placeholder="mm/dd/yyyy"
                    />
                  )}
                />
              </LocalizationProvider>

              <TextField
                id="deductible2"
                label="$ Deductible Amount"
                variant="standard"
                onChange={(e)=>updateInputInsurance(e)}
                value={formState.deductible2 || ""}
                inputProps={{ readOnly: isPreviousVisits }}
                onMouseDown={handleFocus}
              />
            </Box>
              <Box
                component="div"
                sx={{
                  "& .MuiFormControl-root": { flexGrow: 1 },
                  display: "flex",
                  gap: 1,
                  marginTop: 2,
                }}
              >
                <TextField
                  id="deductibleMet2"
                  label="$ Deductible Amount Met"
                  variant="standard"
                  onChange={(e)=>updateInputInsurance(e)}
                  value={formState.deductibleMet2 || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />

                <TextField
                  id="oop2"
                  label="$ Out of Pocket Amount"
                  variant="standard"
                  onChange={(e)=>updateInputInsurance(e)}
                  value={formState.oop2 || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />
                <TextField
                  id="oopMet2"
                  label="$ OOP Amount Met"
                  variant="standard"
                  onChange={(e)=>updateInputInsurance(e)}
                  value={formState.oopMet2 || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />
              </Box>
              <Box
                component="div"
                sx={{
                  "& .MuiFormControl-root": { flexGrow: 1 },
                  display: "flex",
                  gap: 1,
                  marginTop: 2,
                }}
              >
                <TextField
                  id="coIns2"
                  label="CoInsurance"
                  variant="standard"
                  onChange={(e)=>updateInputInsurance(e)}
                  value={formState.coIns2 || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />

                <TextField
                  id="copay2"
                  label="$ Copay"
                  variant="standard"
                  onChange={(e)=>updateInputInsurance(e)}
                  value={formState.copay2 || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      id="inNetwork2"
                      checked={Boolean(formState.inNetwork2)}
                      onChange={isPreviousVisits ? () => { } : (e) => updateInsuranceCheckBox(e)}
                    />
                  }
                  label="Is plan in network?"
                />
              </Box>

              <Box
                component="div"
                sx={{
                  "& .MuiFormControl-root": { flexGrow: 1 },
                  display: "flex",
                  gap: 1,
                  marginTop: 2,
                }}
              >
                <TextField
                  id="policyType2"
                  label="Policy Type"
                  variant="standard"
                  onChange={updateInput}
                  value={formState.policyType2 || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />
                <TextField
                  id="planAdmin2"
                  label="Plan Administrator"
                  variant="standard"
                  onChange={updateInput}
                  value={formState.planAdmin2 || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />
                <TextField
                  id="spokeTo2"
                  label="Spoke to"
                  variant="standard"
                  onChange={updateInput}
                  value={formState.spokeTo2 || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />

              </Box>
              <Box
                component="div"
                sx={{
                  marginTop: 2,
                }}
              >
                <TextField
                  id="referenceNum2"
                  label="Reference #"
                  variant="standard"
                  onChange={updateInput}
                  value={formState.referenceNum2 || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />
              </Box>

              <Box
                component="div"
                sx={{
                  "& .MuiFormControl-root": { flexGrow: 1 },
                  display: "flex",
                  gap: 1,
                  marginTop: 2,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      id="applyDeductible2"
                      checked={Boolean(formState.applyDeductible2)}
                      onChange={isPreviousVisits ? () => { } : (e) => updateInsuranceCheckBox(e)}
                    />
                  }
                  label="Does Deductible Apply "
                />

                <TextField
                  id="deductibleYear2"
                  label="Deductible Calendar Year?"
                  variant="standard"
                  onChange={updateInputInsurance}
                  value={formState.deductibleYear2 || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
              
                 
                />
              </Box>

              <Box
                component="div"
                sx={{
                  "& .MuiFormControl-root": { flexGrow: 1 },
                  display: "flex",
                  gap: 1,
                  marginTop: 2,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      id="priorAuthRequired2"
                      checked={Boolean(formState.priorAuthRequired2)}
                      onChange={isPreviousVisits ? () => { } : updateCheckBox}
                    />
                  }
                  label="PRIOR AUTH REQUIRED?"
                />
              </Box>
              </>}
            <Divider sx={{ mt: 2 , border:"2px solid rgba(0, 0, 0, 0.12)"}} />

              <FormControlLabel sx={{ marginTop: 1 }} control={<Checkbox id="verifiedTertiary" checked={Boolean(formState.verifiedTertiary)} onChange={(e) => handleChangeIns(e)} />} label="Tertiary" />
            {Boolean(formState.verifiedTertiary) && <> <Box
              component="div"
              sx={{
                "& .MuiFormControl-root": { flexGrow: 1 },
                display: "flex",
                gap: 1,
                marginTop: 2,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Eff. date of policy"
                  inputFormat="MM/DD/YYYY"
                  value={formState.policyEffDate3 || ""}
                  onChange={(e)=>updateInsuranceData(e,"policyEffDate3")}
                  readOnly={isPreviousVisits}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      error={errorEDATE && !formState.policyEffDate3}
                      helperText={errorEDATE && !formState.policyEffDate3 ? "required" : ""}
                      value={
                        formState.policyEffDate3 ? moment.utc(formState.policyEffDate3.toLocaleString()).format("MM/DD/YYYY") : ""
                      }
                      inputProps={{ readOnly: isPreviousVisits }}
                      onMouseDown={handleFocus}
                      placeholder="mm/dd/yyyy"
                    />
                  )}
                />
              </LocalizationProvider>

              <TextField
                id="deductible3"
                label="$ Deductible Amount"
                variant="standard"
                onChange={(e)=>updateInputInsurance(e)}
                value={formState.deductible3 || ""}
                inputProps={{ readOnly: isPreviousVisits }}
                onMouseDown={handleFocus}
              />
            </Box>
              <Box
                component="div"
                sx={{
                  "& .MuiFormControl-root": { flexGrow: 1 },
                  display: "flex",
                  gap: 1,
                  marginTop: 2,
                }}
              >
                <TextField
                  id="deductibleMet3"
                  label="$ Deductible Amount Met"
                  variant="standard"
                  onChange={(e)=>updateInputInsurance(e)}
                  value={formState.deductibleMet3 || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />

                <TextField
                  id="oop3"
                  label="$ Out of Pocket Amount"
                  variant="standard"
                  onChange={(e)=>updateInputInsurance(e)}
                  value={formState.oop3 || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />
                <TextField
                  id="oopMet3"
                  label="$ OOP Amount Met"
                  variant="standard"
                  onChange={(e)=>updateInputInsurance(e)}
                  value={formState.oopMet3 || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />
              </Box>
              <Box
                component="div"
                sx={{
                  "& .MuiFormControl-root": { flexGrow: 1 },
                  display: "flex",
                  gap: 1,
                  marginTop: 2,
                }}
              >
                <TextField
                  id="coIns3"
                  label="CoInsurance"
                  variant="standard"
                  onChange={(e)=>updateInputInsurance(e)}
                  value={formState.coIns3 || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />

                <TextField
                  id="copay3"
                  label="$ Copay"
                  variant="standard"
                  onChange={(e)=>updateInputInsurance(e)}
                  value={formState.copay3 || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      id="inNetwork3"
                      checked={Boolean(formState.inNetwork3)}
                      onChange={isPreviousVisits ? () => { } : (e) => updateInsuranceCheckBox(e)}
                    />
                  }
                  label="Is plan in network?"
                />
              </Box>

              <Box
                component="div"
                sx={{
                  "& .MuiFormControl-root": { flexGrow: 1 },
                  display: "flex",
                  gap: 1,
                  marginTop: 2,
                }}
              >
                <TextField
                  id="policyType3"
                  label="Policy Type"
                  variant="standard"
                  onChange={updateInput}
                  value={formState.policyType3 || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />
                <TextField
                  id="planAdmin3"
                  label="Plan Administrator"
                  variant="standard"
                  onChange={updateInput}
                  value={formState.planAdmin3 || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />
                <TextField
                  id="spokeTo3"
                  label="Spoke to"
                  variant="standard"
                  onChange={updateInput}
                  value={formState.spokeTo3 || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />

              </Box>
              <Box
                component="div"
                sx={{
                  marginTop: 2,
                }}
              >
                <TextField
                  id="referenceNum3"
                  label="Reference #"
                  variant="standard"
                  onChange={updateInput}
                  value={formState.referenceNum3 || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                />
              </Box>

              <Box
                component="div"
                sx={{
                  "& .MuiFormControl-root": { flexGrow: 1 },
                  display: "flex",
                  gap: 1,
                  marginTop: 2,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      id="applyDeductible3"
                      checked={Boolean(formState.applyDeductible3)}
                      onChange={isPreviousVisits ? () => { } : (e) => updateInsuranceCheckBox(e)}
                    />
                  }
                  label="Does Deductible Apply "
                />

                <TextField
                  id="deductibleYear3"
                  label="Deductible Calendar Year?"
                  variant="standard"
                  onChange={updateInputInsurance}
                  value={formState.deductibleYear3 || ""}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                  
                  
                />
              </Box>

              <Box
                component="div"
                sx={{
                  "& .MuiFormControl-root": { flexGrow: 1 },
                  display: "flex",
                  gap: 1,
                  marginTop: 2,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      id="priorAuthRequired3"
                      checked={Boolean(formState.priorAuthRequired3)}
                      onChange={isPreviousVisits ? () => { } : updateCheckBox}
                    />
                  }
                  label="PRIOR AUTH REQUIRED?"
                />
              </Box>
              </>}
          </FormGroup>
          {/* <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue=""
            name="planType"
            id="planType"
            value={formState.planType || ""}
            onChange={isPreviousVisits ? () => {} : updateInput}
          >
            <FormControlLabel
              value="Primary"
              control={<Radio />}
              label="Primary"
            />

            <FormControlLabel
              value="Secondary"
              control={<Radio />}
              label="Secondary"
            />

            <FormControlLabel
              value="Tertiary"
              control={<Radio />}
              label="Tertiary"
            />
          </RadioGroup> */}
        </Box>
        <Divider sx={{ mt: 2 , border:"2px solid rgba(0, 0, 0, 0.12)"}} />
        
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            gap: 1,
            marginTop: 2,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                id="referralRequired"
                checked={Boolean(formState.referralRequired)}
                onChange={isPreviousVisits ? () => {} : updateCheckBox}
              />
            }
            label="Referral Required? "
          />
          <TextField
            id="referralRequiredFrom"
            label="Referral Required from who?"
            variant="standard"
            onChange={updateInput}
            value={formState.referralRequiredFrom || ""}
            inputProps={{ readOnly: isPreviousVisits }}
            onMouseDown={handleFocus}
          />
        </Box>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            gap: 1,
            marginTop: 2,
          }}
        >
          <TextField
            id="parContact"
            label="PAR Contact Number & Name"
            variant="standard"
            onChange={updateInput}
            value={formState.parContact || ""}
            inputProps={{ readOnly: isPreviousVisits }}
            onMouseDown={handleFocus}
          />
          <TextField
            id="claimAddress"
            label="Claims Address"
            variant="standard"
            onChange={updateInput}
            value={formState.claimAddress || ""}
            inputProps={{ readOnly: isPreviousVisits }}
            onMouseDown={handleFocus}
          />
        </Box>
        {!asDialog && !isPreviousVisits && isWellnecessitiesUser && (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              data-testid="verification-save-button"
              disabled={isSaveButtonDisabled}
              sx={{ margin: "1rem 0rem" }}
              onClick={saveChanges}
            >
              Save Changes
            </Button>
          </div>
        )}
      </Box>
    );
  }
}
