import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Alert,
  Typography,
} from "@mui/material";
import { useAxios } from "../../../hooks/auth";
import { pulldownsPostData } from "../../../axios/api";

function GeneralPulldown({
  formState,
  updateInput,
  formErrorState,
  attributeName,
  isPreviousVisits = false,
  isPatientPortal = false,
  pulldownApiName,
  itemName,
  labelName,
  dbKeyColumn,
  ...remaining
}) {
  const [locations, setLocations] = useState([]);
  const axios = useAxios();

  useEffect(() => {
    async function setDataFirstTime() {
      let payload = {};
      if (isPatientPortal) {
        payload = {
          patientPortal: 1,
        };
      }else if(formState?.dmeOrderId > 0 && !formState?.orderId){
        payload = {
          dme: 1,
        };
      }
      pulldownsPostData(axios, pulldownApiName, payload)
        .then((res) => {
          setLocations(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setDataFirstTime();
  }, []);

  return (
    <>
      <FormControl
        variant="standard"
        sx={{ mt: 1, mb: 1, minWidth: 120 }}
        {...remaining}
      >
        <InputLabel
          id="location-label"
          error={
            formErrorState[attributeName]?.error && !formState[attributeName]
          }
        >
          {labelName} 
        </InputLabel>
        <Select
          labelId="location-label"
          id={attributeName}
          name={attributeName}
          value={formState[attributeName] || ""}
          label={attributeName}
          onChange={updateInput}
          required
          error={
            formErrorState[attributeName]?.error && !formState[attributeName]
          }
          inputProps={{ readOnly: isPreviousVisits }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {locations.map((type,i) => (
            <MenuItem value={type[dbKeyColumn || attributeName]} key={i}>
              {type[itemName]}
            </MenuItem>
          ))}
        </Select>

        {formErrorState[attributeName]?.error && !formState[attributeName] && (
          <Typography variant="subtitle2" color={"error"}>
            required
          </Typography>
        )}
      </FormControl>
    </>
  );
}

export default GeneralPulldown;
