import { useState } from "react";
import * as React from "react";
import Box from "@mui/material/Box";
import { useAuth, useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "./Dialog";
import {
  FormControl,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { dmeStudyPostData } from "../../axios/api";
import ReschedulePopup from "./ReschedulePopup";
import moment from "moment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FormTable from '../../components/patientPortalComponent/FormTable';
import DMEBrandPulldown from "./pulldowns/DMEBrandPulldown";
import StudyTypePulldown from "./StudyTypePulldown";

export default function DmeConsentFormsDialog({
  handleSave,
  handleClose,
  row,
  setViewCloseDialogState = () => {},
  notificationToastSuccess,
  notificationToastError,
  asDialog = true,
  isPreviousVisits = false,
  notShowPopup,
  consentInfo,
  isCurrentVisits = false,
  reloadCurrentVisit,
  isWellnecessitiesUser
}) {


  const [formErrorState, setFormErrorState] = useState({
    hstSerialNumber:""
  });
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const [patientConfirmDate, setPatientConfirmDate] = useState({});
  const [patientConfirmDateErr, setPatientConfirmDateErr] = useState({});
  const axios = useAxios();
  const paramNameIn = "name";
  const updateCheckBox = (e) => {
    const checkboxId = e.target.id;
    if(checkboxId === "paymentReceived"){
      if (isSaveButtonDisabled) {
        setIsSaveButtonDisabled(false);
      }
      setFormState({
        ...formState,
         [e.target.id || e.target.name]: Number(e.target.checked),
      });
    }else{
      formState["consentFormCompleted"] = 0;
      formState["skipConsentFormCompleted"] = 0;
      formState[checkboxId] = e.target.checked ? 1 : 0;
 
     if (isSaveButtonDisabled) {
       setIsSaveButtonDisabled(false);
     }
     setFormState({
       ...formState,
       //  [e.target.id || e.target.name]: Number(e.target.checked),
     });
    }
   
  };


  React.useEffect(() => {
    if (row) {
      setFormState({
        patientId: row?.patientId,
        orderId: row?.orderIdOrder,
        stageId: row?.stageId,
        surveyId: row?.surveyIdSurvey,
        contacAttempt: row?.contacAttemptOrder,
        studyId: row?.studyIdStudy,
        consentFormCompleted: row?.consentFormCompleted,
        skipConsentFormCompleted: row?.skipConsentFormCompleted,
        patientConfirmedDateTime: row?.patientConfirmedDateTime ? row?.patientConfirmedDateTime : new Date(),
        patientConfirmedBySMS: row?.patientConfirmedBySMS,
        paymentReceived:row?.paymentReceived,
        studyTypeId:row?.studyTypeId,
        outTrackingNum: row?.outTrackingNum,
        trackingNum: row?.trackingNum,
        hstSerialNumber: row?.hstSerialNumber,
        dmeOrderId: row?.dmeOrderIdOrder,
        dmeBrandId: row?.dmeBrandId,
        dmeModelId: row?.dmeModelId
      });
      setPatientConfirmDate(row?.patientConfirmedDateTime ? row?.patientConfirmedDateTime : new Date())
    } else if (consentInfo) {
      setFormState({
        ...formState,
        ...consentInfo,
        consentFormCompleted: consentInfo?.consentFormCompleted,
        skipConsentFormCompleted: consentInfo?.skipConsentFormCompleted,
        paymentReceived: consentInfo?.paymentReceived,
      });
      setPatientConfirmDate(consentInfo?.patientConfirmedDateTime ? consentInfo?.patientConfirmedDateTime : new Date())
    }
  }, [row]);
  
  const [errorMessage, setErrorMessage] = useState("");
  const updateInput = (e) => {
     if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    });
  };
  function getErrorValidationProperty(key) {
    return {
      ...(formState[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }

  
  const saveChanges = (e) => {
    let isValid = true;
    const errors = { ...formErrorState };
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key)) {
        isValid = false;
        errors[key] = { error: true };
      }
    }

    if (!(formState?.studyTypeId === 8 || formState?.studyTypeId === 9 || formState?.studyTypeId === 10) &&
      !formState?.skipConsentFormCompleted &&
      !formState?.consentFormCompleted 
    )
      isValid = false;

    if (!isValid) {
      if(formState?.paymentReceived && formState?.hstSerialNumber) {
          saveStudy(formState);
      }else{
        setFormErrorState(errors)
        setErrorMessage("Please fill all required fields");
        notificationToastError("Please fill all required fields");
  
        return false;
      }
    } else {
      setErrorMessage("");
      saveStudy(formState);
    }

  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const saveStudy = () => {
    setOpen(true);
    setIsSaveButtonDisabled(true);
    const currentTime = moment().format("HH:mm:ss");;
    const payload = {
      ...formState,
      patientConfirmedDateTime: moment(`${moment(formState.patientConfirmedDateTime).format("YYYY-MM-DD")} ${currentTime}`).format("YYYY-MM-DD HH:mm:ss")
    };

    dmeStudyPostData(axios, "updateConsentFormInfo", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
    notificationToastSuccess("success");
    setTimeout(() => {
      setViewCloseDialogState(false);
    }, 2000);
    //  if (isCurrentVisits) window.location.reload(true);

    if (reloadCurrentVisit) reloadCurrentVisit(payload);
  };

  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const updateDATE = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }

    setPatientConfirmDate(newValue.format("MM/DD/YYYY"));
    setFormState({
      ...formState,
      patientConfirmedDateTime: newValue.format("MM/DD/YYYY")
    })
  };

  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleClose}
        confirmButton={{ action: saveChanges, title: "Save" }}
        consentForm="true"
        isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }

  function renderForm(asDialog) {
    return (
      <Box>
        <div>
          {" "}
          <FormControl sx={{ m: "8px 0px" }}>
            <Typography variant="h6"> Patient Consent Forms: </Typography>
          </FormControl>
        </div>
        {!notShowPopup && <ReschedulePopup row={row} />}
          <Box>
          {!notShowPopup && <FormTable patientIdForm={row?.patientId} studyId={row?.studyIdStudy}  consentForm={true} orderId={row?.orderIdOrder}/>}
          
          {notShowPopup && <FormTable patientIdForm={consentInfo?.patientId} studyId={consentInfo?.studyId}  consentForm={true} orderId={consentInfo?.orderId}/>}
        </Box>
        <Box sx={{ m: 1 }}>

          
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
                margin: "10px 0px",
                alignItems: "center",
                flexDirection:"column"
              }}
            >
              <TextField
                sx={{ width: "100%" }}
                id="outTrackingNum"
                label="Outbound Tracking Number"
                value={formState?.outTrackingNum || ""}
                variant="standard"
                onChange={updateInput}
                inputProps={{ readOnly: isPreviousVisits }}
              />

              <TextField
                sx={{ width: "100%" }}
                id="trackingNum"
                label="Inbound Tracking Number"
                value={formState?.trackingNum || ""}
                variant="standard"
                onChange={updateInput}
                inputProps={{ readOnly: isPreviousVisits }}
              />

              <TextField
                sx={{ width: "100%" }}
                id="hstSerialNumber"
                label="DME Serial Number *"
                value={formState?.hstSerialNumber || ""}
                variant="standard"
                onChange={updateInput}
                inputProps={{ readOnly: isPreviousVisits }}
                error={formErrorState?.hstSerialNumber?.error && !formState?.hstSerialNumber}
                helperText={formErrorState?.hstSerialNumber?.error && !formState?.hstSerialNumber ? "required" : ""}
              />
             
            <StudyTypePulldown
              key="studyTypeId"
              isPreviousVisits={isPreviousVisits}
              formState={formState}
              updateInput={updateInput}
              formErrorState={formErrorState}
              attributeName="studyTypeId"
              style={{ width: "100%" }}
              label="Select DME Type"
            />

              <DMEBrandPulldown
                key="dmeBrandId"
                isPreviousVisits={isPreviousVisits}
                formState={formState}
                updateInput={updateInput}
                formErrorState={formErrorState}
                attributeName="dmeBrandId"
                style={{ width: "100%" }}
                pulldownApiName="dmeBrands"
                labelName="DME Brand"
                itemName="brandName"
              />
              {
                formState?.dmeBrandId && formState?.studyTypeId &&
                <DMEBrandPulldown
                  key="dmeModelId"
                  isPreviousVisits={isPreviousVisits}
                  formState={formState}
                  updateInput={updateInput}
                  formErrorState={formErrorState}
                  attributeName="dmeModelId"
                  style={{ width: "100%" }}
                  pulldownApiName="dmeModels"
                  labelName="DME Model"
                  itemName="model"
                />
              }
            </Box>
            
          

          <FormControlLabel
            control={
              <Checkbox
                id="consentFormCompleted"
                checked={Boolean(formState.consentFormCompleted)}
                onChange={isPreviousVisits ? () => {} : updateCheckBox}
              />
            }
            label=" Consent forms completed"
          />
        </Box>
        <Box sx={{ m: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                id="skipConsentFormCompleted"
                checked={Boolean(formState.skipConsentFormCompleted)}
                onChange={isPreviousVisits ? () => {} : updateCheckBox}
              />
            }
            label=" Proceed without completing Consent forms"
          />
        </Box>

        {(formState?.patientConfirmedBySMS == 1) && <Box ml={4}>by TEXT message</Box>}  

        <Box sx={{ m: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                id="paymentReceived"
                checked={Boolean(formState.paymentReceived)}
                onChange={isPreviousVisits ? () => {} : updateCheckBox}
              />
            }
            label=" Payment Received"
          />
        </Box>
        {!asDialog && !isPreviousVisits && isWellnecessitiesUser && (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              sx={{ margin: "1rem 0rem" }}
              disabled={isSaveButtonDisabled}
              onClick={saveChanges}
            >
              Save Changes
            </Button>
          </div>
        )}
      </Box>
    );
  }
}
