
import { useEffect, useState } from "react";
import * as React from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useAxios } from "../../../hooks/auth";
import { dmeBrandPulldownData } from "../../../axios/api";

function DMEBrandPulldown({
  formState,
  updateInput,
  formErrorState,
  attributeName,
  isPreviousVisits = false,
  pulldownApiName,
  labelName,
  itemName,
  ...remaining
}) {
  const [data, setData] = useState([]);
  const axios = useAxios();

  useEffect(() => {
    let payload = pulldownApiName === "dmeModels" ?{
        dmeBrandId : formState?.dmeBrandId,
        studyTypeId : formState?.studyTypeId    
    } : undefined
      async function setDataFirstTime() {
          dmeBrandPulldownData(axios, pulldownApiName,payload)
              .then((res) => {
                  setData(res?.data);   
              })
              .catch((err) => {
                  console.log(err);
              });
      }
          setDataFirstTime();
  }, [formState.dmeBrandId]);

  return (
    <>
      <FormControl
        variant="standard"
        sx={{ mt: 1, mb: 1, minWidth: 120 }}
        {...remaining}
      >
        <InputLabel
          id="brand-label"
          error={
            formErrorState[attributeName]?.error && !formState[attributeName]
          }
        >
          {labelName} 
        </InputLabel>
        <Select
          labelId="brand-label"
          id={attributeName}
          name={attributeName}
          value={formState[attributeName] || ""}
          label={attributeName}
          onChange={updateInput}
          required
          error={
            formErrorState[attributeName]?.error && !formState[attributeName]
          }
          inputProps={{ readOnly: isPreviousVisits }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {data.map((item,i) => (
            <MenuItem value={item[attributeName]} key={i}>
              {item[itemName]}
            </MenuItem>
          ))}
        </Select>

        {formErrorState[attributeName]?.error && !formState[attributeName] && (
          <Typography variant="subtitle2" color={"error"}>
            required
          </Typography>
        )}
      </FormControl>
    </>
  );
}

export default DMEBrandPulldown;
