import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import * as React from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { useAuth, useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "./Dialog";
import {
  FormControl,
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Dialog,
  useMediaQuery,
  Grid,
  Button,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import GeneralPulldown from "./pulldowns/GeneralPulldown";
import LocationPulldown from "./LocationPulldown";
import PrefInterpPhysicianPulldown from "./PrefInterpPhysicianPulldown";
import PhysicianPulldown from "./PhysicianPulldown";
import StudyTypePulldown from "./StudyTypePulldown";
import { studyPostData, pulldownsPostData, dmeStudyPostData } from "../../axios/api";
import ReschedulePopup from "./ReschedulePopup";
import DMEBrandPulldown from "./pulldowns/DMEBrandPulldown";

export default function RescheduleStudyDialog({
  handleSave,
  handleCloseSchedule,
  row,
  setViewCloseDialogState = () => {},
  notificationToastSuccess,
  notificationToastError,
  isPreviousVisits = false,
  isPatientPortal,
  rescheduleData,
  asDialog = true,
  notShowPopup,
  isWellnecessitiesUser
}) {
  const [formErrorState, setFormErrorState] = useState({shipDate:""});
  const { cookies } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const axios = useAxios();
  const paramNameIn = "name";
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
 

  React.useEffect(() => {
    if (row) {
      const newFormState = {
        physicianId: row?.physicianIdStudy,
        patientId: row?.patientId,
        orderId: row?.orderIdOrder,
        stageId: row?.stageId,
        statusNameId: row?.statusNameId,
        prefInterpPhysId: row?.prefInterpPhysId,
        SDATE: row?.date,
        studyTypeId: row?.studyTypeId,
        locationId: row?.locationIdStudy,
        SHIP: row?.shipDate,
        studyId: row?.studyIdStudy,
        time: row?.time,
        reasonReschedulingId: row?.reasonReschedulingId,
        canceled: row?.canceled || row?.requestedCancelStudy,
        reasonCancelingId: row?.reasonCancelingId,
        hstDeviceId: row?.hstDeviceId,
        reasonAuthDenial: row?.reasonAuthorDenial,
        deviceReturnDate: row?.deviceReturnDate,
        reschedule: row?.reschedule || row?.requestedReschedule,
        primaryInsuranceId: row?.primaryInsuranceIdP,
        secondaryInsuranceId: row?.secondaryInsuranceIdP,
        primaryPolicy: row?.primaryPolicyP,
        primaryGroup: row?.primaryGroupP,
        secondaryPolicy: row?.secondaryPolicyP,
        secondaryGroup: row?.secondaryGroupP,
        reasonApptResched: row?.reasonApptResched,
        reasonApptCanc: row?.reasonApptCanc,
        requestedCancelStudy: row?.requestedCancelStudy,
        reasonIncomplete: row?.reasonIncomplete,
        pickup:row?.pickup,
        shipDate:row?.shipDate,
        dmeOrderId: row?.dmeOrderIdOrder,
        dmeBrandId: row?.dmeBrandId,
        dmeModelId: row?.dmeModelId
      };
 
      if (row?.canceled || row?.requestedCancelStudy) {
        if (isSaveButtonDisabled) setIsSaveButtonDisabled(false);
      
       }
      
      setFormState(newFormState);
      setDeviceReturnDate(row?.deviceReturnDate);
      setSDATE(row?.date);
      setSHIP(row?.shipDate);
      if (newFormState.studyTypeId && newFormState.prefInterpPhysId) ScheduleLocation(newFormState);

      

    } else if (rescheduleData) {
      setFormState({ ...formState, ...rescheduleData });
      setDeviceReturnDate(rescheduleData?.deviceReturnDate);
      if (rescheduleData.studyTypeId && rescheduleData.prefInterpPhysId)
        ScheduleLocation(rescheduleData);
    }
  }, [row]);

  function getErrorValidationProperty(key) {
    return {
      ...(formState[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }

  //update device return due date
  const updateDueDate = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setDeviceReturnDate(newValue.format("MM/DD/YYYY"));
    setFormState({
      ...formState,
      deviceReturnDate: newValue.format("YYYY-MM-DD"),
    });
  };
  const updateCheckBox = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: Number(e.target.checked),
    });
    setIsSaveButtonDisabled(false);
  };
  const [deviceReturnDate, setDeviceReturnDate] = useState(null);
  const [deviceReturnDateErr, setDeviceReturnDateErr] = useState(null);
  const [locations, setLocations] = useState([]);

  const handleMainCheckboxChange = (event) => {
    const checkboxId = event.target.id;
    formState["reschedule"] = 0;
    formState["canceled"] = 0;

    formState[checkboxId] = event.target.checked ? 1 : 0;
    setFormState({ ...formState });
    setIsSaveButtonDisabled(false);
  };

  const updateInput = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    const newFormState = {
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    };
    setFormState(newFormState);

    if (newFormState.studyTypeId && newFormState.prefInterpPhysId)
      ScheduleLocation(newFormState);
  };


  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key) && !formState.canceled ) {
        if(key === 'shipDate'){
          if(!formState.shipDate && (formState?.studyTypeId === 8 || formState?.studyTypeId === 9 || formState?.studyTypeId === 10)){
            isValid = false;
            errors[key] = { error: true };
          }else{
            errors[key] = { error: false };
          }
        }else{
          isValid = false;
          errors[key] = { error: true };
        }
      }
    }
    if ((formState?.studyTypeId === 8 || formState?.studyTypeId === 9 || formState?.studyTypeId === 10) && !deviceReturnDate){
      isValid = false;
      setDeviceReturnDateErr(true)
    }

 /*   if (
      !formState.reasonReschedulingId &&
      (formState?.reschedule === "1" ||
        formState?.reschedule === 1 ||
        formState.canceled == null ||
        formState?.canceled == "0" ||
        formState?.canceled == 0)
    ) {
      isValid = false;
      formErrorState["reasonReschedulingId"] = { error: true };
    }
    if (
      !formState.reasonCancelingId &&
      (formState?.canceled === "1" || formState?.canceled === 1)
    ) {
      isValid = false;
      formErrorState["reasonCancelingId"] = { error: true };
    }
    */

    if (!isValid) {
      //setErrorMessage("Please fill all required fields");
     // setFormErrorState(formErrorState);
    
      notificationToastError("Please fill all required fields");
      setFormErrorState(errors);
      return false;
    }
    saveStudy(formState);
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  function ScheduleLocation(payload) {
    pulldownsPostData(axios, "scheduleLocationPulldown", payload)
      .then((res) => {
        if (res && res.data) setLocations(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const saveStudy = () => {
    setOpen(true);
    setIsSaveButtonDisabled(true);
    const payload = {
      ...formState,
      date: SDATE || formState.SDATE,
      shipDate: SHIP || formState.SHIP || formState.shipDate,
      deviceReturnDate:
        formState?.studyTypeId === 8 ||
        formState?.studyTypeId === 9 ||
        formState?.studyTypeId === 10
          ? formState.deviceReturnDate
          : "",
      rescheduleData: undefined,
    };
   
    
    if (formState?.dmeOrderId > 0 && !formState?.orderId) {
      dmeStudyPostData(axios, "saveStudy", payload)
        .then((res) => {
          if (res?.data?.response === "1") {
            notificationToastSuccess(res?.data?.message);
          } else {
            notificationToastError(res?.data?.message);
          }
        })
        .catch((err) => {
          notificationToastError(err?.message);
        });
      notificationToastSuccess("success");
      setTimeout(() => {
        setViewCloseDialogState(false);
      }, 2000);
    } else {
      studyPostData(axios, "saveStudy", payload)
        .then((res) => {
          if (res?.data?.response === "1") {
            notificationToastSuccess(res?.data?.message);
          } else {
            notificationToastError(res?.data?.message);
          }
        })
        .catch((err) => {
          notificationToastError(err?.message);
        });
      notificationToastSuccess("success");
      setTimeout(() => {
        setViewCloseDialogState(false);
      }, 2000);
    }
  };

  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  //pickup handlechange
  const pickupCheckboxChange = (event) => {
    const checkboxId = event.target.id;

    formState[checkboxId] = event.target.checked ? 1 : 0;
    setFormState({ ...formState });
    setIsSaveButtonDisabled(false);
  };

  const [SHIP, setSHIP] = React.useState(null);
  const [errorSHIP, setErrorSHIP] = React.useState(false);
  const updateSHIP = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setSHIP(newValue.format("YYYY-MM-DD"));
    setFormState({ ...formState, shipDate: newValue.format("YYYY-MM-DD") });
  };

  const [SDATE, setSDATE] = React.useState(null);
  const [errorSDATE, setErrorSDATE] = React.useState(false);
  const updateSDATE = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setSDATE(newValue.format("YYYY-MM-DD"));
  };
  // const patientNameVal = row[2];

  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleCloseSchedule}
        confirmButton={{ action: saveChanges, title: "Save" }}
        isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }

  function renderForm(asDialog) {

    return (
      <>
        <div>
          {" "}
          <FormControl sx={{ mt: 2, mb: 2 }}>
            <Typography variant="h6">
            Confirm Reschedule / Cancel Appointment:{" "}
            </Typography>
          </FormControl>
        </div>

        {asDialog && !notShowPopup && <ReschedulePopup row={row} />}
        <Box sx={{ marginTop: "1rem" }}>
          {formState?.reasonAuthDenial !== 0 &&  formState?.reasonApptCanc === null && formState?.reasonApptResched === null &&
            formState?.reasonAuthDenial !== null && (
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <TextField
                  id="reasonAuthDenial"
                  label="Authorization Denied"
                  variant="standard"
                  value={formState?.reasonAuthDenial || ""}
                  readOnly
                  InputProps={{ disableUnderline: true }}
                />
              </FormControl>
            )}
        </Box>
        
        { formState?.statusNameId !== 12 && (
        <Box sx={{ marginTop: "1rem" }}>
           <Typography variant="h6">Reason:{" "} </Typography>
           { ( formState?.requestedCancelStudy === 1 && formState?.reasonApptCanc !== null) && ( formState?.reasonApptCanc  )}
        {  ( formState?.requestedCancelStudy !== 1 &&  formState?.reasonApptResched !== null) && ( formState?.reasonApptResched  )}
        {  formState?.reasonIncomplete && ( formState?.reasonIncomplete  )}
      
        </Box>
        )}
        <Box  component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
              gap: "1rem",
              marginTop: "1rem",
            }}>
          <FormControlLabel
            control={
              <Checkbox
                id="reschedule"
                checked={Boolean(formState.reschedule)}
                onChange={handleMainCheckboxChange}
              />
            }
            label="Confirm Reschedule Appointment"
          />

          <FormControlLabel
            control={
              <Checkbox
                id="canceled"
                checked={Boolean(formState.canceled)}
                onChange={handleMainCheckboxChange}
              />
            }
            label="Confirm Cancellation Appointment"
          />
        </Box>


        {formState?.reschedule == 1 && (
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
              gap: "1rem",
              marginTop: "1rem",
            }}
          >
            <FormControl variant="standard" sx={{ width: "50%", mt: 1, mb: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={formState?.dmeOrderId > 0 && !formState?.orderId ? "DME Appointment Date *":"Study Date *"}
                  inputFormat="MM/DD/YYYY"
                  value={SDATE || formState.SDATE || ""}
                  onChange={updateSDATE}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      error={errorSDATE && !SDATE}
                      helperText={errorSDATE && !SDATE ? "required" : ""}
                      value={
                        SDATE
                          ? moment
                              .utc(SDATE.toLocaleString())
                              .format("MM/DD/YYYY")
                          : ""
                      }
                      onBlur={() => {
                        setErrorSDATE(!SDATE);
                      }}
                      onMouseDown={handleFocus}
                      placeholder="mm/dd/yyyy"
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>

            <FormControl variant="standard" sx={{ width: "50%" }}>
              <TextField
                id="time"
                label="Select Time"
                type="time"
                defaultValue="08:00"
                value={formState.time || ""}
                onChange={updateInput}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Box>
        )}

        {formState?.reschedule == 1 && (
          <StudyTypePulldown
            key="studyTypeId"
            formState={formState}
            updateInput={updateInput}
            formErrorState={formErrorState}
            attributeName="studyTypeId"
            style={{ maxWidth: "300px" }}
            label={formState?.dmeOrderId > 0 && !formState?.orderId ? "Select DME Type *":"Select Study Type *"}
          />
        )}

        {formState?.dmeOrderId > 0 && !formState?.orderId && formState?.reschedule == 1 ?
          <>
            <DMEBrandPulldown
              key="dmeBrandId"
              isPreviousVisits={isPreviousVisits}
              formState={formState}
              updateInput={updateInput}
              formErrorState={formErrorState}
              attributeName="dmeBrandId"
              style={{ width: "100%" }}
              pulldownApiName="dmeBrands"
              labelName="DME Brand"
              itemName="brandName"
            />
            {
              formState?.dmeBrandId && formState?.studyTypeId &&
              <DMEBrandPulldown
                key="dmeModelId"
                isPreviousVisits={isPreviousVisits}
                formState={formState}
                updateInput={updateInput}
                formErrorState={formErrorState}
                attributeName="dmeModelId"
                style={{ width: "100%" }}
                pulldownApiName="dmeModels"
                labelName="DME Model"
                itemName="model"
              />
            }
          </>
          : ""
        }

        {formState?.reschedule == 1 &&
          (formState?.studyTypeId === 8 ||
            formState?.studyTypeId === 9 ||
            formState?.studyTypeId === 10) && (
            <Box
              component="div"
              sx={{
                "& .MuiFormControl-root": { flexGrow: 1 },
                display: "flex",
                gap: "1rem",
                alignItems:"center"
              }}
            >
              <>
                <GeneralPulldown
                  key="hstDeviceId"
                  isPreviousVisits={isPreviousVisits}
                  formState={formState}
                  updateInput={updateInput}
                  formErrorState={formErrorState}
                  attributeName="hstDeviceId"
                  style={{ width: "100%" }}
                  isPatientPortal={isPatientPortal}
                  pulldownApiName="hstDevicesPulldown"
                  itemName="deviceName"
                  labelName="Select HST Device"
                />
              </>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Ship Date*"
                  inputFormat="MM/DD/YYYY"
                  value={SHIP || formState.SHIP || ""}
                  onChange={updateSHIP}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      error={formErrorState.shipDate?.error && !SHIP}
                      helperText={formErrorState.shipDate?.error && !SHIP ? "required" : ""}
                      value={
                        SHIP
                          ? moment
                              .utc(SHIP.toLocaleString())
                              .format("MM/DD/YYYY")
                          : ""
                      }
                      onBlur={() => {
                        setErrorSHIP(!SHIP);
                      }}
                      onMouseDown={handleFocus}
                    />
                  )}
                />
              </LocalizationProvider>{" "}
              <FormControlLabel
              sx={{ mt: 2 }}
                control={
                  <Checkbox
                    id="pickup"
                    checked={Boolean(formState.pickup)}
                    onChange={pickupCheckboxChange}
                  />
                }
                label="Pickup"
              />
            </Box>
          )}

        {formState?.reschedule == 1 &&
          (formState?.studyTypeId === 8 ||
            formState?.studyTypeId === 9 ||
            formState?.studyTypeId === 10) && (
            <Box
              component="div"
              sx={{
                "& .MuiFormControl-root": { flexGrow: 1 },
                display: "flex",
                gap: "1rem",
              }}
            >
            
              <FormControl variant="standard" sx={{ width: "50%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Device Return Due Date *"
                    inputFormat="MM/DD/YYYY"
                    className="study-date-data"
                    value={
                      deviceReturnDate || formState?.deviceReturnDate || ""
                    }
                    onChange={updateDueDate}
                    readOnly={isPreviousVisits}
                    renderInput={(params) => (
                      <TextField
                        // data-testid="study-date-data"
                        {...params}
                        variant="standard"
                        error={deviceReturnDateErr && !deviceReturnDate}
                        helperText={
                          deviceReturnDateErr && !deviceReturnDate
                            ? "required"
                            : ""
                        }
                        value={
                          deviceReturnDate
                            ? moment
                                .utc(deviceReturnDate.toLocaleString())
                                .format("MM/DD/YYYY")
                            : ""
                        }
                        onBlur={() => {
                          setDeviceReturnDateErr(!deviceReturnDate);
                        }}
                        inputProps={{ readOnly: isPreviousVisits }}
                        onMouseDown={handleFocus}
                        placeholder="mm/dd/yyyy"
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </Box>
          )}

        {formState?.reschedule == 1 && (
          <PhysicianPulldown
            formState={formState}
            updateInput={updateInput}
            attributeName={"physicianId"}
          />
        )}

        {formState?.reschedule == 1 && (
          <PrefInterpPhysicianPulldown
            formState={formState}
            updateInput={updateInput}
            formErrorState={formErrorState}
            attributeName="prefInterpPhysId"
            physicianId={row?.physicianIdOrder}
            label={formState?.dmeOrderId > 0 && !formState?.orderId ? "Select Following Physician" : "Select Preferred Interpreting Physician"}
          />
        )}
        {formState?.reschedule == 1 && (
          <FormControl
            variant="standard"
            sx={{ minWidth: "49%", margin: "8px 0px" }}
          >
            <InputLabel
              id="location-label"
              error={
                formErrorState?.locationId?.error && !formState?.locationId
              }
            >
              {formState?.dmeOrderId > 0 && !formState?.orderId ? "DME Provider *" : "Facility *"}
            </InputLabel>
            <Select
              labelId="location-label"
              id="locationId"
              name="locationId"
              value={formState.locationId || ""}
              label="location"
              onChange={updateInput}
              required
              error={formErrorState.locationId?.error && !formState.locationId}
              inputProps={{ readOnly: isPreviousVisits }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {locations.map((location) => (
                <MenuItem value={location.locationId} key={location.locationId}>
                  {location.location}
                </MenuItem>
              ))}
            </Select>
            {formErrorState?.locationId?.error && !formState?.locationId && (
              <Typography variant="subtitle2" color={"error"}>
                required
              </Typography>
            )}
          </FormControl>
        )}
  
        {!asDialog && !isPreviousVisits && isWellnecessitiesUser && (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              disabled={isSaveButtonDisabled}
              sx={{ margin: "1rem 0rem" }}
              onClick={saveChanges}
              data-testid="schedule-save-Button"
            >
              Save Changes
            </Button>
          </div>
        )}
      </>
    );
  }
}
