import TextField from "@mui/material/TextField";
import { useState } from "react";
import * as React from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { useAuth, useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "./Dialog";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Checkbox,
  Dialog,
  useMediaQuery,
  Tooltip,
  Button,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import FollowingPhysicianPulldown from "./FollowingPhysicianPulldown";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import LocationPulldown from "./LocationPulldown";
import PrefInterpPhysicianPulldown from "./PrefInterpPhysicianPulldown";
import PhysicianPulldown from "./PhysicianPulldown";
import StudyTypePulldown from "./StudyTypePulldown";
import {
  studyPostData,
  resultsPostData,
  patientsPostData,
} from "../../axios/api";
import InterpFileUpload from "../../pages/patients/components/FileUpload/InterpFileUpload";
import ReschedulePopup from "./ReschedulePopup";
import classes from "../adminComponent/documents/documentDialog/DocumentDialog.module.css";
import Documentupload from "../adminComponent/documents/documentUpload/Documentupload";
import OrderView from "../../pages/patients/components/OrderView";
import DmeOrder from "../../pages/patients/dmeOrder/DmeOrder";
import { Download, Visibility } from "@mui/icons-material";
import FileView from "../../pages/patients/components/FileUpload/FileView";
import { Buffer } from "buffer";

export default function UploadInterpretationDialog({
  handleSave,
  handleClose,
  row,
  setViewCloseDialogState = () => {},
  notificationToastSuccess,
  notificationToastError,
  asDialog = true,
  isPreviousVisits = false,
  interpretationData = {},
  notShowPopup,
  isCurrentVisits = false,
  reloadCurrentVisit,
  isWellnecessitiesUser
}) {
  const [formErrorState, setFormErrorState] = useState({});
  const { cookies } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const axios = useAxios();
  const paramNameIn = "name";
  const [refreshData, setRefreshData] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isNewOrderOpen, setIsNewOrderOpen] = useState(false);
  const [isDmeOrderOpen, setIsDmeOrderOpen] = useState(false);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [pdfURLState, setPdfURLState] = useState({ fileUrl: "", fileType: "" });

  const isMobile = useMediaQuery("(max-width:800px)");
  const [studyInfoFormState, setStudyInfoFormState] = useState({});

  React.useEffect(() => {
    if (row?.studyId) {
   
      setFormState({
        physicianId: row?.physicianIdStudy,
        patientId: row?.patientId,
        orderId: row?.orderIdOrder,
        surveyId: row?.surveyIdSurvey,
        stageId: row?.stageId,
        prefInterpPhysId: row?.prefInterpPhysId,
        SDATE: row?.date,
        // studyTypeId: row[100],
        locationId: row?.locationIdStudy,
        SHIP: row?.shipDate,
        trackingNum: row?.trackingNum,
        studyId: row?.studyId,
        email: row?.email,
        cellPhone: row?.cellPhone,
        apptLocationName: row?.locationStudy,
        patientNameIn: row?.patientName,
        autoCPAP: row?.autoCPAP,
        dmeOrder: row?.dmeOrder,
        followupSleepSpecialist: row?.followupSleepSpecialist,
        dob: row?.dob,
        scheduleSecondAppt: row?.scheduleSecondAppt,
        followUpOrder: row?.followUpOrder,
        parentOrderCpapId: row?.parentOrderCpapId,
        parentOrderId: row?.parentOrderId,
        followupPhysicianId: row?.followupPhysicianId,
        followUpInterpretationDate: row?.followUpInterpretationDate,
        interpretationDate: row?.interpretationDate,
        followUpInterpretationNotes: row?.followUpInterpretationNotes
      });
      setSDATE(row?.interpretationDate)
      setFDATE(row?.followUpInterpretationDate)

      if (row?.physicianIdOrder && !row?.followupPhysicianId) {
        const payload = {
          physicianId: row?.physicianIdOrder,
        };

        resultsPostData(axios, "followingPhysicianId", payload)
          .then((res) => {
            if (res?.data) {
              const followupPhysicianId = res.data[0]["followingPhysId"];
          
              setFormState({
                ...formState,
                followupPhysicianId,
                studyId: row?.studyIdStudy || row?.studyId,
                patientId: row?.patientId,
                orderId: row?.orderIdOrder,
              });
            } else {
            }
          })
          .catch((err) => {
            notificationToastError(err?.message);
          });
      }
    } else {
      setFormState({ ...formState, ...interpretationData, inActiveField: interpretationData?.scheduleSecondAppt || interpretationData?.followUpOrder });
      setSDATE(interpretationData?.interpretationDate)
      setFDATE(interpretationData?.followUpInterpretationDate)
    }
  }, [row]);


  let patientName;
  if (row) {
    patientName = row?.patientName;
  }

  let isParentOrderId = 0;
  /* if (row && (row[252]> 0 || row[253] > 0)) { 
       isParentOrderId = "The follow up order is already available." ;
    //   formState["followUpOrder"] = 1; 
       if (row[252]> 0 )  formState["autoCPAP"] = 1; 
       if (row[253]> 0 )  formState["dmeOrder"] = 1;

  }
  */

  const handleCloseNewOrder = () => {
    setIsNewOrderOpen(false);
    setIsDmeOrderOpen(false);
  };

  const handleMainCheckboxChange1 = (event) => {
    const checkboxId = event.target.id;
    formState["scheduleSecondAppt"] = 0;
    formState["followUpOrder"] = 0;

    formState[checkboxId] = event.target.checked ? 1 : 0;
    setFormState({ ...formState });
    setIsSaveButtonDisabled(false);
  };

  const handleMainCheckboxChange = (event) => {
    const checkboxId = event.target.id;
    formState["followupSleepSpecialist"] = 0;
    formState["autoCPAP"] = 0;
    formState["dmeOrder"] = 0;

    formState[checkboxId] = event.target.checked ? 1 : 0;
    if (formState["autoCPAP"] == 1) setIsNewOrderOpen(true);
    if (formState["dmeOrder"] == 1) setIsDmeOrderOpen(true);
    setFormState({ ...formState });
    setIsSaveButtonDisabled(false);
  };

  const updateInput = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    });
    setIsSaveButtonDisabled(false);
  };

  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };

    if (FDATE) {
      saveStudy(formState);
      return true;
    }

    if (
      formState?.followUpOrder &&
      !formState?.followupSleepSpecialist &&
      !formState?.autoCPAP &&
      !formState?.dmeOrder
    ) {
      notificationToastError("Please Select One of Checkboxes ");
      isValid = false;
    }

   if (!formState?.file && (formState.scheduleSecondAppt || formState.followUpOrder) && !SDATE ) {
     notificationToastError("Please Upload Interpretation File  ");
     isValid = false;
   }

    if (!formState?.file && (formState.scheduleSecondAppt || formState.followUpOrder) && SDATE && !(formState?.interpretationDate && isCurrentVisits)) {
      notificationToastError("Please Upload Interpretation File  ");
      isValid = false;
    }

    if (!formState?.file && SDATE && !(formState?.interpretationDate && isCurrentVisits)) {
      notificationToastError("Please Upload Interpretation File  ");
      isValid = false;
    }

    if (formState?.scheduleSecondAppt === 1 && !formState?.studyTypeId) {
      notificationToastError("Please Select Study Type ");
      isValid = false;
    }

    if (!isValid) {
      setErrorMessage("Please fill all required fields");
      setFormErrorState({ ...formErrorState });
      return false;
    }
    saveStudy(formState);
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const saveStudy = () => {
    setOpen(true);
    setIsSaveButtonDisabled(true);
    const payload = {
      ...formState,
      scheduleSecondAppt: formState?.scheduleSecondAppt,
      interpretationDate: SDATE ? SDATE == "Invalid Date" ? "" : moment(SDATE).format('YYYY-MM-DD') : "",
      followUpInterpretationDate: FDATE ? FDATE == "Invalid Date" ? "" : moment(FDATE).format('YYYY-MM-DD') : "",
    };

    resultsPostData(axios, "interpretationRecommendation", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
    notificationToastSuccess("success");
    setTimeout(() => {
      setViewCloseDialogState(false);
    }, 2000);
    if (reloadCurrentVisit) reloadCurrentVisit(payload);
  };

  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const [SHIP, setSHIP] = React.useState(null);
  const [errorSHIP, setErrorSHIP] = React.useState(false);
  const updateSHIP = (newValue) => {
    newValue && setSHIP(newValue.format("YYYY-MM-DD"));
  };

  const [SDATE, setSDATE] = React.useState(null);
  const [errorSDATE, setErrorSDATE] = React.useState(false);
  const updateSDATE = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    newValue && setSDATE(newValue.format("YYYY-MM-DD"));
  };
  const patientNameVal = row?.patientName ?? formState.patientNameVal;


  //Follow-Up for Interpretation date

  const [FDATE, setFDATE] = useState(null);
  const [errorFDATE, seterrorFDATE] = useState(null)
  const updateFDATE = (newValue) =>{
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    newValue && setFDATE(newValue.format("YYYY-MM-DD"));
  }

  // download and view file

  const base64ToPdf = (base64String, fileType) => {
    let typeFile = fileType === "pdf" ? "application/pdf" : "image/png";
    const buffer = Buffer.from(base64String, "base64");
    const url = URL.createObjectURL(new Blob([buffer], { type: typeFile }));
    return url;
  };

  const download = (id, tableName, idColumn, isView) => {
    const payload = {
      id,
      tableName: tableName,
      idColumn: idColumn,
    };
    patientsPostData(axios, "downloadFile", payload)
      .then((res) => {
        if (res.data.file) {
          if (isView === "view") {
            setOpenPdfModal(true);
            let base64 = res?.data?.file;
            base64 = base64?.split(",")[1];
            let fileType = res?.data?.name;
            fileType = fileType?.split(".")?.pop();
            const pdfUrl = base64ToPdf(base64, fileType);
            setPdfURLState({
              fileUrl: pdfUrl,
              fileType,
            });
          } else {
            notificationToastSuccess("File is downloading.");
            const downloadLink = document.createElement("a");
            downloadLink.href = res.data.file;
            downloadLink.download = res.data.name;
            downloadLink.click();
          }
        } else {
          notificationToastError("File is not uploaded yet to download.");
        }
        // setRefreshData(!refreshData);
      })
      .catch((err) => notificationToastError(err?.message));
  };

  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleClose}
        confirmButton={{ action: saveChanges, title: "Save" }}
        isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }

  function renderForm(asDialog) {
    return (
      <Box>
        <div>
          {" "}
          <FormControl sx={{ m: "8px 0px" }}>
            <b>
              Upload Interpretation and Select Recommendations for Followup:{" "}
            </b>
          </FormControl>
        </div>

        <Box sx={{ marginTop: "1rem" }}>
          {!notShowPopup && (
            <ReschedulePopup
              row={row}
              dob={formState.dob}
              dos="Completed DOS:"
            />
          )}
        </Box>
        <>
          <Box sx={{ marginTop: "1rem" }}>
            <Grid item lg={6} md={5} sm={12} xs={12}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <Box
                  component="div"
                  sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    display: "flex",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="scheduleSecondAppt"
                        checked={Boolean(formState.scheduleSecondAppt)}
                        onChange={handleMainCheckboxChange1}
                        disabled={Boolean(formState?.inActiveField) || isPreviousVisits}
                      />
                    }
                    label="Follow Up Study"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="followUpOrder"
                        checked={Boolean(formState.followUpOrder)}
                        onChange={handleMainCheckboxChange1}
                        disabled={Boolean(formState?.inActiveField) || isPreviousVisits}
                      />
                    }
                    label="Follow Up Order"
                  />
                </Box>
              </FormControl>
            </Grid>
          </Box>

          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
              marginTop: 1,
              margin:"8px 8px"
            }}
          >
            {formState?.scheduleSecondAppt === 1 && (
              <StudyTypePulldown
                key="studyTypeId"
                formState={formState}
                updateInput={updateInput}
                formErrorState={formErrorState}
                attributeName="studyTypeId"
                style={{ maxWidth: "500px" }}
              />
            )}
          </Box>

          <Box sx={{ marginTop: "1rem" }}>
            <Grid item lg={6} md={5} sm={12} xs={12}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
              { isParentOrderId !== 0 && ( <Box sx={{ marginBottom: "1rem" }}
                  ><b> {isParentOrderId} </b> </Box>) }

                { (formState?.followUpOrder === 1 && isParentOrderId === 0) && (
                  <Box
                    component="div"
                    sx={{
                      "& .MuiFormControl-root": { flexGrow: 1 },
                      display: "flex",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="autoCPAP"
                          checked={Boolean(formState.autoCPAP)}
                          onChange={handleMainCheckboxChange}
                          disabled={Boolean(formState?.inActiveField) || isPreviousVisits}
                        />
                      }
                      label="Sleep Study"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          id="dmeOrder"
                          checked={Boolean(formState.dmeOrder)}
                          //onChange={updateCheckBox}
                          onChange={handleMainCheckboxChange}
                          disabled={Boolean(formState?.inActiveField) || isPreviousVisits}
                        />
                      }
                      label="DME order"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="followupSleepSpecialist"
                          checked={Boolean(formState.followupSleepSpecialist)}
                          onChange={handleMainCheckboxChange}
                          disabled={Boolean(formState?.inActiveField) || isPreviousVisits}
                        />
                      }
                      label="Followup with Sleep Specialist"
                    />
                  </Box>
                )}

                  <Box
                    component="div"
                    sx={{
                      "& .MuiFormControl-root": { flexGrow: 1 },
                      display: "flex",
                      mt: 2, mb: 2
                    }}
                  >
                    <FollowingPhysicianPulldown
                      formState={formState}
                      updateInput={updateInput}
                      formErrorState={formErrorState}
                      attributeName="followupPhysicianId"
                      isPreviousVisits={isPreviousVisits}
                    />
                  </Box>
                

                <Box
                  component="div"
                  sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    display: "flex",
                    margin: "0px 8px",
                  }}
                >
                  <FormControl >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Follow-Up for Interpretation"
                        inputFormat="MM/DD/YYYY"
                        value={FDATE || ""}
                        onChange={updateFDATE}
                        readOnly={isPreviousVisits}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            error={errorFDATE && !FDATE}
                            helperText={errorFDATE && !FDATE ? "required" : ""}
                            value={
                              FDATE
                                ? moment
                                  .utc(FDATE.toLocaleString())
                                  .format("MM/DD/YYYY")
                                : ""
                            }
                            onBlur={() => {
                              seterrorFDATE(!FDATE);
                            }}
                            {...(isPreviousVisits && {
                              inputProps: { readOnly: isPreviousVisits },
                            })}
                            onMouseDown={handleFocus}
                            placeholder="mm/dd/yyyy"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl
                    variant="standard"
                    sx={{ width: "98%", marginTop: 1.5 ,pl:1}}
                  >
                    Notes
                    <TextareaAutosize
                      id="followUpInterpretationNotes"
                      label="Notes"
                      value={formState?.followUpInterpretationNotes || ""}
                      variant="standard"
                      onChange={updateInput}
                      rows={2}
                    />
                  </FormControl>
                </Box>
                <Box
                  component="div"
                  sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    display: "flex",
                    margin: "8px 8px",
                  }}
                >
                  <FormControl >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Interpretation Date"
                        inputFormat="MM/DD/YYYY"
                        value={SDATE || ""}
                        onChange={updateSDATE}
                        readOnly={isPreviousVisits}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            error={errorSDATE && !SDATE}
                            helperText={errorSDATE && !SDATE ? "required" : ""}
                            value={
                              SDATE
                                ? moment
                                  .utc(SDATE.toLocaleString())
                                  .format("MM/DD/YYYY")
                                : ""
                            }
                            onBlur={() => {
                              setErrorSDATE(!SDATE);
                            }}
                            {...(isPreviousVisits && {
                              inputProps: { readOnly: isPreviousVisits },
                            })}
                            onMouseDown={handleFocus}
                            placeholder="mm/dd/yyyy"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Box>
                 {isPreviousVisits || (isCurrentVisits && (formState?.date || formState?.interpretationDate)) ? (
                  <Box
                    className={classes.documentWrapper}
                    sx={{ marginTop: 2 }}
                  >
                    <Box
                      component="div"
                      sx={{
                        "& .MuiFormControl-root": { flexGrow: 1 },
                        display: "flex",
                        marginTop: 2,
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Typography variant="strong">
                        Interpretation report:{" "}
                      </Typography>

                      <Tooltip title="View" placement="bottom">
                        <Button
                          sx={{ width: 60 }}
                          variant="contained"
                          size="small"
                          onClick={download.bind(
                            null,
                            formState.interpFileId,
                            "storedFiles",
                            "fileId",
                            "view"
                          )}
                        >
                          <Visibility />
                        </Button>
                      </Tooltip>

                      <Tooltip title="Download" placement="bottom">
                        <Button
                          sx={{ width: 60 }}
                          variant="contained"
                          size="small"
                          onClick={download.bind(
                            null,
                            formState.interpFileId,
                            "storedFiles",
                            "fileId"
                          )}
                        >
                          <Download />
                        </Button>
                      </Tooltip>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    className={classes.documentWrapper}
                    sx={{ marginTop: 2 }}
                  >
                    <Box
                      component="div"
                      sx={{
                        "& .MuiFormControl-root": { flexGrow: 1 },
                        display: "flex",
                        marginTop: 2,
                        marginX: 1,
                      }}
                    >
                      Upload Interpretation
                      <Documentupload
                        data-testid="document-upload" // Add Cypress ID to the Documentupload component
                        setFormState={setFormState}
                        setIsSaveButtonDisabled={setIsSaveButtonDisabled}
                        formErrorState={formErrorState}
                        setFormErrorState={setFormErrorState}
                        formState={formState}
                      />
                    </Box>
                  </Box>
                )}
              </FormControl>
              {isNewOrderOpen && (
                <Dialog
                  open={isNewOrderOpen}
                  onClose={handleCloseNewOrder}
                  data-testid="order-survey-dialog"
                  sx={{
                    "& .MuiDialog-paper": {
                      maxWidth: isMobile ? "auto" : "1200px",
                      minHeight: isMobile ? "auto" : "400px",
                    },
                    "& .MuiDialogContent-root": {
                      display: "flex",
                      flexDirection: "column",
                    },
                  }}
                >
                  <OrderView
                    isOrderModal
                    handleClose={handleCloseNewOrder}
                    reschedulePopup={true}
                    data={formState}
                    followUpOrder={true}
                  />
                </Dialog>
              )}

              {isDmeOrderOpen && (
                <Dialog
                  open={isDmeOrderOpen}
                  onClose={handleCloseNewOrder}
                  data-testid="order-survey-dialog"
                  sx={{
                    "& .MuiDialog-paper": {
                      maxWidth: isMobile ? "auto" : "1200px",
                      minHeight: isMobile ? "auto" : "400px",
                    },
                    "& .MuiDialogContent-root": {
                      display: "flex",
                      flexDirection: "column",
                    },
                  }}
                >
                  <DmeOrder
                    isOrderModal
                    handleClose={handleCloseNewOrder}
                    data={formState}
                    followUpOrder={true}
                  />
                </Dialog>
              )}

              {openPdfModal && (
                <FileView
                  setOpenPdfModal={setOpenPdfModal}
                  openPdfModal={openPdfModal}
                  pdfURLState={pdfURLState}
                />
              )}
            </Grid>
          </Box>
          {!asDialog && !isPreviousVisits && (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                disabled={isSaveButtonDisabled}
                sx={{ margin: "1rem 0rem" }}
                onClick={saveChanges}
                data-testid="schedule-save-Button"
              >
                Save Changes
              </Button>
            </div>
          )}
        </>
      </Box>
    );
  }
}
