import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import * as React from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { useAuth, useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../components/misc/Dialog";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import classes from "../patientsComponent/currentVisits/currentVisits.module.css";
import StudyTypePulldown from "../../components/misc/StudyTypePulldown";
import { dmeStudyPostData, pulldownsPostData, dmeOrderPostData } from "../../axios/api";

import {
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import phoneNumberValidator from "../../utils/phoneNumberValidator";
import DmeOrder from "../../pages/patients/dmeOrder/DmeOrder";
import PrefInterpPhysicianPulldown from "./PrefInterpPhysicianPulldown";
import DMEBrandPulldown from "./pulldowns/DMEBrandPulldown";

export default function TreatmentScheduleStudyDialog({
  handleSave,
  handleClose,
  row,
  setViewCloseDialogState = () => {},
  notificationToastSuccess = () => {},
  notificationToastError = () => {},
  asDialog = true,
  isPreviousVisits = false,
  treatmentScheduleStudyData = {},
  isPatientPortal 
}) {
  const [formErrorState, setFormErrorState] = useState({
    studyTypeId: "",
    locationId: "",
    SDATE: "",
    time: ""
    
  });

  const [selectedDate, handleDateChange] = useState(new Date());

  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const axios = useAxios();
  const paramNameIn = "name";
  const [isDmeOrderOpen, setIsDmeOrderOpen] = useState(false);
  const [surveyAndOrderId, setSurveyAndOrderId] = useState({});
  const isMobile = useMediaQuery("(max-width:800px)");


  React.useEffect(() => {
    if (row) {
      const newFormState = {
        physicianId: row?.physicianIdDmeOrder,
        patientId: row?.patientId,
        surveyId: row?.surveyIdSurvey,
        orderId: row?.orderIdOrder,
        stageId: row?.stageId,
        orderingPhysicianName: row?.name,
        prefInterpPhysId: row?.prefInterpPhysId,
        time: row?.time,
        dmeOrderId: row?.dmeOrderIdOrder,
        dmeBrandId: row?.dmeBrandId,
        dmeModelId: row?.dmeModelId
      };
    
       // Get study type from Order
       if (!row?.studyTypeId ) {
        
        if (row?.dmeOrderId) {
          
          let payload;
          payload = {
            dmeOrderId: row?.dmeOrderId,
          };
          dmeOrderPostData(axios, "viewDmeOrder", payload)
            .then((res) => {
           
              if (res?.data?.length) {
                const orderDetails = res?.data[0];
         
                let studyTypeId = "";
              
                if (orderDetails["requestedStudyTypeId"]) studyTypeId = orderDetails["requestedStudyTypeId"];
                
                if (studyTypeId) {
                  const newFormStateWithStudyTypeId = {
                    ...newFormState,
                    studyTypeId,
                  };
                  setFormState(newFormStateWithStudyTypeId);
                }
              }
            })
            .catch((err) => {});
        }
      }


      setFormState(newFormState);
      if (newFormState?.studyTypeId) ScheduleLocation(newFormState);
    } else if (treatmentScheduleStudyData) {
      // console.log("treatmentScheduleStudyData=====>", treatmentScheduleStudyData);
      setFormState({ ...formState, ...treatmentScheduleStudyData });
      setSDATE(treatmentScheduleStudyData?.studyDate);
      // setCDATE(treatmentScheduleStudyData?.contactedPatientDate);
      if (treatmentScheduleStudyData?.studyTypeId) ScheduleLocation(treatmentScheduleStudyData);
    }
  }, [row]);

  const [SHIP, setSHIP] = React.useState(null);
  const [errorSHIP, setErrorSHIP] = React.useState(false);
  const updateSHIP = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setSHIP(newValue.format("MM/DD/YYYY"));
    setFormState({ ...formState, shipDate: newValue.format("YYYY-MM-DD") });
  };

  //pickup handlechange
  const pickupCheckboxChange = (event) => {
    const checkboxId = event.target.id;

    formState[checkboxId] = event.target.checked ? 1 : 0;
    setFormState({ ...formState });
    setIsSaveButtonDisabled(false);
  };

  const [locations, setLocations] = useState([]);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const updateInput = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    const newFormState = {
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    };
    setFormState(newFormState);
    if (newFormState.studyTypeId && newFormState.prefInterpPhysId)
      ScheduleLocation(newFormState);
  };
  function ScheduleLocation(payload) {
    pulldownsPostData(axios, "scheduleLocationPulldown", payload)
      .then((res) => {
        if (res && res.data) setLocations(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };
    console.log('formErrorState', formErrorState)
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key)) {
        isValid = false;
        errors[key] = { error: true };
      }
    }

    if (!isValid) {
      setFormErrorState(errors);
      return false;
    }

    saveStudy(formState);
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const saveStudy = () => {
    setOpen(true);
    setIsSaveButtonDisabled(true);
    const payload = {
      ...formState,
      date: SDATE,
      // contactedPatientDate: CDATE,
      
    };
    dmeStudyPostData(axios, "saveTreatmentStudy", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
    notificationToastSuccess("success");
    setTimeout(() => {
      setViewCloseDialogState(false);
    }, 2000);
  };
  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };
/*
  const [CDATE, setCDATE] = React.useState(null);
  const [errorCDATE, setErrorCDATE] = React.useState(false);
  const updateCDATE = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setCDATE(newValue.format("YYYY-MM-DD"));
    setFormState({ ...formState, CDATE: newValue.format("YYYY-MM-DD") });
  };
*/
  const [SDATE, setSDATE] = React.useState(null);
  const [errorSDATE, setErrorSDATE] = React.useState(false);
  const updateSDATE = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setSDATE(newValue.format("YYYY-MM-DD"));
    setFormState({ ...formState, SDATE: newValue.format("YYYY-MM-DD") });
  };

  const updateTime = (newValue) => {
    setFormState({ ...formState, time: newValue.format("hh:mm a") });
  };

  const handleDMEOrder = () => {
    setIsDmeOrderOpen(true);
    setSurveyAndOrderId({
      // orderId : origOrderId || orderId,
      patientId: row?.patientId,
      dmeOrderId: row?.dmeOrderId,
      isDmeOrderOpen: true,
      stages : row?.stages,
      stageId : row?.stageId,
    });
  };
 console.log('formState', formState,row)
  let patientNameVal;
  const bestPhoneVal = phoneNumberValidator(row?.bestPhone) ?? formState?.bestPhone;
  if (row) {
    patientNameVal = row?.patientName;
  }
  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleClose}
        confirmButton={{ action: saveChanges, title: "Save" }}
        isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }

  function renderForm(asDialog) {
    return (
      <div data-testid="schedule-study-data">
        <div>
          {" "}
          <FormControl sx={{ m: "8px 0px" }}>
            <Typography variant="h6" data-testid="appoinment-text">
              {" "}
              {!asDialog
                ? "Appointment Info :"
                : "Please schedule in treatment appointment to the patient and enter the appointment information below: "}
            </Typography>
          </FormControl>
        </div>
        {asDialog && (
          <>
          <TextField
            id="patientName"
            label="Patient Name"
            variant="standard"
            value={patientNameVal}
            readOnly
            InputProps={{ disableUnderline: true }}
          />
            <TextField
              id="bestPhone"
              label="Best Contact Number "
              variant="standard"
              value={bestPhoneVal?.formattedPhoneNumber || ""}
              readOnly
              InputProps={{ disableUnderline: true, readOnly: true }}
            />
            <Box
                component="div"
                sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    display: "flex",
                    marginTop: 2
                }}
            >
              <Grid item lg={3.9} md={4} xs={12}>
                {/* {item?.origOrderId && ( */}
                <>
                  <Typography variant="strong">DME Order : </Typography>
                  <Typography
                    data-testid="order-open-link"
                    variant="link"
                    className={classes?.visitDate}
                    onClick={() => {
                      handleDMEOrder();
                    }}
                  >
                    Click here to open DME Order
                  </Typography>
                </>
                {/* )} */}
              </Grid>
            </Box>
          </>
        )}

        <Box
          component="div"
          sx={{
            // "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            margin: "8px 0px",
          }}
        >

          <FormControl variant="standard" sx={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="DME Appointment Date *"
                inputFormat="MM/DD/YYYY"
                className="study-date-data"
                value={SDATE || ""}
                onChange={updateSDATE}
                readOnly={isPreviousVisits}
                renderInput={(params) => (
                  <TextField
                  // data-testid="study-date-data"
                    {...params}
                    variant="standard"
                    error={formErrorState.SDATE?.error && !SDATE}
                    helperText={
                      formErrorState.SDATE?.error && !SDATE ? "required" : ""
                    }
                    value={
                      SDATE ? moment.utc(SDATE.toLocaleString()).format("MM/DD/YYYY") : ""
                    }
                    onBlur={() => {
                      setErrorSDATE(!SDATE);
                    }}
                    inputProps={{ readOnly: isPreviousVisits }}
                    onMouseDown={handleFocus}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
         
          <FormControl variant="standard" sx={{ width: "50%" }}>
            <TextField
              id="time"
              variant="standard"
              label="Select Time"
              type="time"
              defaultValue="08:00"
              value={formState.time || ""}
              required
              error={formErrorState.time?.error && !formState.time}
              onChange={updateInput}
              InputLabelProps={{
                shrink: true,
              }}
              readOnly={isPreviousVisits}
            />
            {formErrorState?.time?.error && !formState?.time && (
              <Typography variant="subtitle2" color={"error"}>
                required
              </Typography>
            )}
          </FormControl>
        </Box>
        <StudyTypePulldown
          key="studyTypeId"
          isPreviousVisits={isPreviousVisits}
          formState={formState}
          updateInput={updateInput}
          formErrorState={formErrorState}
          attributeName="studyTypeId"
          style={{ width: "100%" }}
          isPatientPortal={isPatientPortal}
          label = "Select DME Type"
        />

        <DMEBrandPulldown
          key="dmeBrandId"
          isPreviousVisits={isPreviousVisits}
          formState={formState}
          updateInput={updateInput}
          formErrorState={formErrorState}
          attributeName="dmeBrandId"
          style={{ width: "100%" }}
          pulldownApiName="dmeBrands"
          labelName="DME Brand"
          itemName="brandName"
        />
        {
          formState?.dmeBrandId && formState?.studyTypeId &&
          <DMEBrandPulldown
            key="dmeModelId"
            isPreviousVisits={isPreviousVisits}
            formState={formState}
            updateInput={updateInput}
            formErrorState={formErrorState}
            attributeName="dmeModelId"
            style={{ width: "100%" }}
            pulldownApiName="dmeModels"
            labelName="DME Model"
            itemName="model"
          />
        }

        {(formState?.studyTypeId === 8 ||
          formState?.studyTypeId === 9 ||
          formState?.studyTypeId === 10) && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
              margin: "8px 0px",
              alignItems:"center"
            }}
          >
              
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Ship Date*"
                inputFormat="MM/DD/YYYY"
                value={SHIP || ""}
                onChange={updateSHIP}
                readOnly={isPreviousVisits}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    error={formErrorState.shipDate?.error && !SHIP}
                    sx={{ width: "100%" }}
                    helperText={formErrorState.shipDate?.error && !SHIP ? "required" : ""}
                    value={
                      SHIP ? moment.utc(SHIP.toLocaleString()).format("MM/DD/YYYY") : ""
                    }
                    inputProps={{ readOnly: isPreviousVisits }}
                    onBlur={() => {
                      setErrorSHIP(!SHIP);
                    }}
                    onMouseDown={handleFocus}
                    placeholder="mm/dd/yyyy"
                  />
                )}
              />
            </LocalizationProvider>{" "}

            {/* pickup label */}
            <FormControlLabel
              control={
                <Checkbox
                  id="pickup"
                  checked={Boolean(formState.pickup)}
                  onChange={pickupCheckboxChange}
                />
              }
              label="Pickup"
              sx={{marginTop:"12px"}}
            />
          </div>
        )}

<Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            margin: "8px 0px",
            alignItems: "center"
          }}
        >
          <TextField
            sx={{ width: "100%" }}
            id="orderingPhysicianName"
            data-testid="order-physician-id"
            label="Ordering Physician"
            variant="standard"
            value={formState.orderingPhysicianName || ""}
            inputProps={{ readOnly: isPreviousVisits }}
            // InputProps={{ disableUnderline: true }}
          />
          <PrefInterpPhysicianPulldown
            formState={formState}
            updateInput={updateInput}
            formErrorState={formErrorState}
            attributeName="prefInterpPhysId"
            isPreviousVisits={isPreviousVisits}
            isPatientPortal={isPatientPortal}
            physicianId={formState.physicianId}
            label="Select Following Physician"
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            margin: "8px 0px",
            alignItems: "center"
          }}>
          <FormControl
            variant="standard"
            sx={{ minWidth: "49%", margin: "8px 0px" }}
          >
            <InputLabel
              id="location-label"
              error={
                formErrorState?.locationId?.error && !formState?.locationId
              }
            >
              DME Provider *
            </InputLabel>
            <Select
              labelId="location-label"
              id="locationId"
              name="locationId"
              value={formState.locationId || ""}
              label="location"
              onChange={updateInput}
              required
              error={formErrorState.locationId?.error && !formState.locationId}
              inputProps={{ readOnly: isPreviousVisits }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {locations.map((location) => (
                <MenuItem value={location.locationId} key={location.locationId}>
                  {location.location}
                </MenuItem>
              ))}
            </Select>
            {formErrorState?.locationId?.error && !formState?.locationId && (
              <Typography variant="subtitle2" color={"error"}>
                required
              </Typography>
            )}
          </FormControl>
        </Box>

        {isDmeOrderOpen && (
        <Dialog
          open={isDmeOrderOpen}
          onClose={handleClose}
          data-testid="order-survey-dialog"
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: isMobile ? "auto" : "1200px",
              minHeight: isMobile ? "auto" : "400px",
            },
            "& .MuiDialogContent-root": {
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          {surveyAndOrderId?.isDmeOrderOpen && (
            <DmeOrder
              surveyAndOrderId={surveyAndOrderId}
              isOrderModal
              handleClose={handleClose}
            />
          )}
        </Dialog>
      )}

        {!asDialog && !isPreviousVisits && (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              disabled={isSaveButtonDisabled}
              sx={{ margin: "1rem 0rem" }}
              onClick={saveChanges}
              data-testid="schedule-save-Button"
            >
              Save Changes
            </Button>
          </div>
        )}
      </div>
    );
  }
}
